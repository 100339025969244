//種別変換表
export const typeMap: { [index: string]: string } = {
  copy: "コピー",
  enemy: "てきキャラ",
  friend: "みかたキャラ",
};

export const subTypeMap: { [index: string]: string } = {
  copy: "コピーのうりょく",
  mix: "ミックスコピー",
  special: "とくしゅのうりょく",
  metamor: "メタモルのうりょく",
  super: "スーパーのうりょく",
  campus: "キャンパスへんしん",
  job: "ジョブ",
  robo: "ロボ",
  friends: "フレンズのうりょく",
  saiho: "さいほうのうりょく",
  shinka: "コピーのうりょくしんか",
  hobari: "ほおばりヘンケイ",
  food: "コピーフード",
  boss: "ボスキャラ",
  midboss: "中ボスキャラ",
  zako: "ザコキャラ",
  friend: "なかまキャラ",
  friend_copy: "なかまコピーのうりょく",
  airride_machine: "エアライドマシン",
  ball: "ボール",
  vehicle: "のりもの",
  other: "そのたキャラ",
};
