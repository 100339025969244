//react
import React, { useContext } from "react";
//component
import { renderImageContainer } from "../utility/CommonFunc";
import { GlobalValueContext } from "../App";
//type
import { ImageType } from "../App";

const Copy = () => {
  const imageList = useContext(GlobalValueContext)?.copyList || [];

  const renderNormal = (imageList: Array<ImageType>) => {
    return (
      <>
        <h1>コピーのうりょく</h1>
        {renderImageContainer(
          "copy",
          "copy",
          <h2>コピーのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "mix",
          <h2>ミックスコピー</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "special",
          <h2>とくしゅのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "metamor",
          <h2>メタモルのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "super",
          <h2>スーパーのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "campus",
          <h2>キャンパスへんしん</h2>,
          imageList
        )}
        {renderImageContainer("copy", "job", <h2>ジョブ</h2>, imageList)}
        {renderImageContainer("copy", "robo", <h2>ロボ</h2>, imageList)}
        {renderImageContainer(
          "copy",
          "friends",
          <h2>フレンズのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "saiho",
          <h2>さいほうのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "shinka",
          <h2>コピーのうりょくしんか</h2>,
          imageList
        )}
        {renderImageContainer(
          "copy",
          "hobari",
          <h2>ほおばりヘンケイ</h2>,
          imageList
        )}
        {renderImageContainer("copy", "food", <h2>コピーフード</h2>, imageList)}
      </>
    );
  };
  return <>{renderNormal(imageList)}</>;
};

export default Copy;
