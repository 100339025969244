//react
import { useState, useContext } from "react";
import axios from "axios";
//css
import "../../css/wordle.css";
//component
import WordleDeeAnswer from "./WordleDeeAnswer";
import WordleDeeInput from "./WordleDeeInput";
//context
import { GlobalValueContext } from "../../App";
import { GlobalSetValueContext } from "../../App";
//function
import { setCookie, getCookie, makeRandomNum } from "../../utility/Utility";
import { loadData } from "../../utility/CommonFunc";
import { hasOnlyKana, isClose, renderRecord } from "./WordleDeeFunc";
//type
import { ImageType } from "../../App";
//const
const initAnswerList = [
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
  ["　", "　", "　", "　", "　"],
];
const initAnswerMatchList = [
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
  ["empty", "empty", "empty", "empty", "empty"],
];

const WordleDeeMain = () => {
  //context
  const enemyList = useContext(GlobalValueContext)?.enemyList || [];
  const setHiscoreList = useContext(GlobalSetValueContext)?.setHiscoreList;
  const setFootprintsList = useContext(
    GlobalSetValueContext
  )?.setFootprintsList;
  const filteredEnemyList = enemyList.filter((value) => {
    return value.name.length === 5 && hasOnlyKana(value.name);
  });
  //state
  const [gameScene, setGameScene] = useState("title");
  const [account, setAccount] = useState(getCookie("account"));
  const [score, setScore] = useState(0);
  const [answerRowNum, setAnswerRowNum] = useState(0);
  const [answerColNum, setAnswerColNum] = useState(0);
  const [targetAnswer, setTargetAnswer] = useState("");
  const [inputLock, setInputLock] = useState(false);
  const [answerList, setAnswerList] =
    useState<Array<Array<string>>>(initAnswerList);
  const [answerMatchList, setAnswerMatchList] =
    useState<Array<Array<string>>>(initAnswerMatchList);
  const [matchPerfectList, setMatchPerfectList] = useState<Array<string>>([]);
  const [closePerfectList, setClosePerfectList] = useState<Array<string>>([]);
  const [matchLetterList, setMatchLetterList] = useState<Array<string>>([]);
  const [notMatchList, setNotMatchList] = useState<Array<string>>([]);
  const [hintDispFlag, setHintDispFlag] = useState(false);

  const getPath = (list: Array<ImageType>, imageName: string) => {
    const targetImage = list.find((value) => {
      return value.name === imageName;
    });
    return targetImage?.img_path || "";
  };

  const handleStart = () => {
    setScore(0);
    setAnswerRowNum(0);
    setAnswerColNum(0);
    setInputLock(false);
    setAnswerList(initAnswerList);
    setAnswerMatchList(initAnswerMatchList);
    setMatchPerfectList([]);
    setClosePerfectList([]);
    setMatchLetterList([]);
    setNotMatchList([]);
    setHintDispFlag(false);

    setTargetAnswer(
      filteredEnemyList[makeRandomNum(filteredEnemyList.length - 1)].name
    );
    setCookie("account", account);
    setGameScene("ingame");
  };

  const renderTitle = () => {
    return (
      <div>
        <div>ハンドルネーム</div>
        <div>
          <input
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
            }}
            placeholder="ハンドルネーム"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleStart();
              }
            }}
          />
        </div>
        <div>
          <button onClick={handleStart} disabled={account === ""}>
            はじめる
          </button>
        </div>
      </div>
    );
  };

  const setAnswerMatchListByRowCol = (
    rowNum: number,
    colNum: number,
    value: string
  ) => {
    setAnswerMatchList((oldMatchList) => {
      return oldMatchList.map((matchRow, indexRow) => {
        return matchRow.map((matchCol, indexCol) => {
          if (indexRow === rowNum && indexCol === colNum) {
            return value;
          }
          return matchCol;
        });
      });
    });
  };

  const checkAnswer = (rowNum: number, colNum: number) => {
    if (targetAnswer.charAt(colNum) === answerList[rowNum][colNum]) {
      setAnswerMatchListByRowCol(rowNum, colNum, "match-perfect");
      setMatchPerfectList((oldList) => {
        return [...oldList, answerList[rowNum][colNum]];
      });
    } else if (
      isClose(targetAnswer.charAt(colNum), answerList[rowNum][colNum])
    ) {
      setAnswerMatchListByRowCol(rowNum, colNum, "close-perfect");
      setClosePerfectList((oldList) => {
        return [...oldList, answerList[rowNum][colNum]];
      });
    } else if (targetAnswer.includes(answerList[rowNum][colNum])) {
      setAnswerMatchListByRowCol(rowNum, colNum, "match-letter");
      setMatchLetterList((oldList) => {
        return [...oldList, answerList[rowNum][colNum]];
      });
    } else {
      setAnswerMatchListByRowCol(rowNum, colNum, "not-match");
      setNotMatchList((oldList) => {
        return [...oldList, answerList[rowNum][colNum]];
      });
    }
  };

  const handleEnter = () => {
    console.log(targetAnswer);
    if (inputLock) {
      return;
    }
    setInputLock(true);
    const checkRowNum = answerRowNum;
    setTimeout(() => {
      checkAnswer(checkRowNum, 0);
      setTimeout(() => {
        checkAnswer(checkRowNum, 1);
        setTimeout(() => {
          checkAnswer(checkRowNum, 2);
          setTimeout(() => {
            checkAnswer(checkRowNum, 3);
            setTimeout(() => {
              checkAnswer(checkRowNum, 4);
              if (getAnswerString() === targetAnswer) {
                setScore(100 - 10 * checkRowNum);
                setGameScene("result");
              }
              if (answerRowNum === 9) {
                setScore(0);
                setGameScene("result");
              }
              setInputLock(false);
            }, 500);
          }, 500);
        }, 500);
      }, 500);
    }, 500);
    if (answerRowNum === 9) {
      return;
    }
    setAnswerRowNum((oldValue) => {
      return oldValue + 1;
    });
    setAnswerColNum(0);
  };

  const handleDelete = () => {
    if (inputLock) {
      return;
    }
    if (answerColNum === 0) {
      return;
    }
    setAnswerList((oldAnswer) => {
      return oldAnswer.map((answer, indexRow) => {
        return answer.map((letter, indexCol) => {
          if (indexRow === answerRowNum && indexCol === answerColNum - 1) {
            return "　";
          }
          return letter;
        });
      });
    });
    setAnswerColNum((oldValue) => {
      return oldValue - 1;
    });
  };

  const handleInputLetter = (inputLetter: string) => {
    if (inputLock) {
      return;
    }
    if (answerColNum >= 5) {
      return;
    }
    setAnswerList((oldAnswer) => {
      return oldAnswer.map((answer, indexRow) => {
        return answer.map((letter, indexCol) => {
          if (indexRow === answerRowNum && indexCol === answerColNum) {
            return inputLetter;
          }
          return letter;
        });
      });
    });
    setAnswerColNum((oldValue) => {
      return oldValue + 1;
    });
  };

  const getAnswerString = () => {
    let returnValue = "";
    answerList[answerRowNum].forEach((value) => {
      returnValue += value;
    });
    return returnValue;
  };

  const renderIngame = () => {
    return (
      <div className="d-wordle-body">
        <div className="d-wordle-body-inner">
          <div>挑戦者：{account}</div>
          <div>現在のスコア：{100 - 10 * answerRowNum}</div>
          <WordleDeeAnswer
            answerList={answerList}
            answerMatchList={answerMatchList}
          />
          <div className="p-text-center">
            {answerColNum === 5 &&
            filteredEnemyList.find((value) => {
              return value.name === getAnswerString();
            }) === undefined
              ? "存在しないワードです"
              : "　"}
          </div>
          <WordleDeeInput
            enterFunc={handleEnter}
            deleteFunc={handleDelete}
            inputFunc={handleInputLetter}
            matchPerfectList={matchPerfectList}
            closePerfectList={closePerfectList}
            matchLetterList={matchLetterList}
            notMatchList={notMatchList}
            enterDisabled={
              inputLock ||
              answerColNum !== 5 ||
              filteredEnemyList.find((value) => {
                return value.name === getAnswerString();
              }) === undefined
            }
            deleteDisabled={inputLock || answerColNum === 0}
          />
          <div>
            <div className="d-wordle-inputzone-function">
              <div>
                <button
                  onClick={() => {
                    setScore(0);
                    setGameScene("result");
                  }}
                >
                  ギブアップ
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setHintDispFlag(true);
                  }}
                  disabled={
                    answerRowNum < 9 ||
                    getPath(enemyList, getAnswerString()) === ""
                  }
                  title={
                    answerRowNum < 9
                      ? "最終回答時にヒントを表示"
                      : getPath(enemyList, getAnswerString()) === ""
                      ? "ヒントイラストがありません"
                      : ""
                  }
                >
                  ヒント
                </button>
              </div>
            </div>
          </div>
          {hintDispFlag && getPath(enemyList, getAnswerString()) !== "" ? (
            <>
              <div>
                <div>今日のお題のヒントイラスト</div>
                <img src={getPath(enemyList, getAnswerString())} alt="Hint" />
              </div>
            </>
          ) : (
            <div className="p-container-center">ヒント画像はありません</div>
          )}
        </div>
      </div>
    );
  };

  const handleRegist = () => {
    axios
      .post("/app/api/score", {
        data: window.location.hostname,
        account: account,
        game: "wordledee",
        score: score,
        remarks: JSON.stringify(answerMatchList),
      })
      .then((response) => {
        if (setHiscoreList !== undefined) {
          loadData([
            {
              apiPath: "hiscore",
              dataSet: [{ dataName: "result", setFunc: setHiscoreList }],
            },
          ]);
        }
        if (setFootprintsList !== undefined) {
          loadData([
            {
              apiPath: "footprints",
              dataSet: [{ dataName: "result", setFunc: setFootprintsList }],
            },
          ]);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    setGameScene("result");
  };

  const renderResult = () => {
    return (
      <div>
        <div>
          <h3>けっかはっぴょう</h3>
          <div>
            正解は「{targetAnswer}」でした。あなたのスコアは
            <span className="rainbow">{score}点</span>です
          </div>
          <div className="p-container-center">
            {renderRecord(answerMatchList)}
          </div>
          <div>
            <button onClick={handleRegist}>とうろく</button>
          </div>
          <div>
            <button onClick={handleStart}>もどる</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {gameScene === "ingame"
        ? renderIngame()
        : gameScene === "result"
        ? renderResult()
        : renderTitle()}
    </>
  );
};

export default WordleDeeMain;
