//react
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ScrollTileImage from "../common/ScrollTileImage";
//context
import { GlobalValueContext } from "../../App";
//function
import { shuffle } from "../../utility/Utility";

const TopFriend = () => {
  const imageList = useContext(GlobalValueContext)?.friendList || [];
  const filteredImageList = imageList.filter((value) => {
    return value.img_path !== "";
  });
  const shuffledImageList = shuffle(filteredImageList);
  const limitedImageList = shuffledImageList.filter((value, index) => {
    return index < 30;
  });
  return (
    <>
      <h2>
        <i className="fas fa-heart p-acd-icon-star" />
        <Link to="/friend">なかまキャライラスト</Link>
      </h2>
      <ScrollTileImage imageList={limitedImageList} />
    </>
  );
};

export default TopFriend;
