//react
import React from "react";
import { Link } from "react-router-dom";

const TopGuestBook = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <h2>
            <i className="fas fa-book p-acd-icon-star" />
            <Link to="/guestbook">ゲストブック</Link>
          </h2>
        </div>
      </div>
    </>
  );
};

export default TopGuestBook;
