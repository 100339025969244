//react
import React, { useContext } from "react";
//component
import { GlobalValueContext } from "../App";
import AccordionMenu from "../parts/common/AccordionMenu";
//type
import { LinkType } from "../App";

const Link = () => {
  const currentLinkList = useContext(GlobalValueContext)?.linkList || [];
  const oldLinkList = useContext(GlobalValueContext)?.unlinkList || [];

  const renderLink = (linkList: Array<LinkType>) => {
    return linkList.map((value) => {
      return (
        <div key={value.site_name}>
          <a href={value.site_url} rel="noreferrer noopener" target="_blank">
            {value.img_url !== "" ? (
              <div className="p-overlay-container">
                <img
                  src={value.img_url}
                  alt={value.site_name}
                  title={value.site_name + "\n管理人:" + value.manager + " 様"}
                  className={
                    value.remarks.includes("archive")
                      ? "p-image-border p-link-image"
                      : "p-link-image"
                  }
                />
                <p className="p-overlay-linktext-down">
                  {value.site_name}(管理人:{value.manager}様)
                </p>
              </div>
            ) : (
              <div className="p-overlay-container">
                <img
                  src={"img/p0049.png"}
                  alt={value.site_name}
                  title={value.site_name + "\n管理人:" + value.manager + " 様"}
                  className={
                    value.remarks.includes("archive")
                      ? "p-image-border p-link-image"
                      : "p-link-image"
                  }
                />
                <p className="p-overlay-linktext-down">
                  {value.site_name}(管理人:{value.manager}様)
                </p>
              </div>
            )}
          </a>
        </div>
      );
    });
  };

  return (
    <>
      <h1>リンク</h1>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="リンクについて"
            id="aboutLink"
            url=""
            contents={
              <>
                当サイトはリンクフリーです。相互リンクご希望の方はご連絡ください。
                <br />
                <br />
                <table className="p-table">
                  <tbody>
                    <tr>
                      <td>サイト名</td>
                      <td>プププ騎士団</td>
                    </tr>
                    <tr>
                      <td>管理人</td>
                      <td>rick</td>
                    </tr>
                    <tr>
                      <td>ジャンル</td>
                      <td>カービィメタナイトファンサイト</td>
                    </tr>
                    <tr>
                      <td>開設日</td>
                      <td>2004年9月19日</td>
                    </tr>
                    <tr>
                      <td>URL</td>
                      <td>
                        <a href="https://www.pupupuorder.com/">
                          https://www.pupupuorder.com/
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>バナー</td>
                      <td>
                        <div>バナーは保存推奨です</div>
                        <div>
                          <a href="img/p0307.png">
                            <img src="img/p0307.png" alt="バナー１" />
                            (88x31)
                          </a>
                        </div>
                        <div>
                          <a href="img/p0046.gif">
                            <img src="img/p0046.gif" alt="バナー２" />
                            (124x50)
                          </a>
                        </div>
                        <div>
                          <a href="img/p0047.png">
                            <img src="img/p0047.png" alt="バナー３" />
                            (200x40)
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
          />
        </div>
      </div>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <h2>リンク</h2>
          <div className="p-link-container">{renderLink(currentLinkList)}</div>
        </div>
      </div>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title={<>リンクぎれサイト</>}
            id="oldLink"
            url=""
            contents={
              <div className="p-link-container">{renderLink(oldLinkList)}</div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Link;
