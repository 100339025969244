//react
import React from "react";
import AccordionMenu from "../../parts/common/AccordionMenu";

const TopAbout = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="このサイトについて"
            id="about"
            url=""
            contents={
              <>
                <p>
                  カービィ＆メタナイトファンサイト『
                  <a href="https://www.pupupuorder.com/">プププ騎士団</a>
                  』へようこそ！
                  <br />
                  このサイトはカービィとメタナイトのファンのための交流サイトです。
                  <br />
                  更新は不定期ですが、薄く長くサイトの維持は続けていきたいと思っています。
                  <br />
                </p>
                <p>
                  当サイトは、任天堂株式会社及び株式会社ハル研究所とは無関係の非公式な個人運営サイトです。
                  <br />
                  各ゲームや各キャラクターの権利は各社に帰属します。権利保持者より要請があった場合にはそれに従います。
                  <br />
                  当サイト内に記載されている会社名・製品名・ゲーム名等は、各社の登録商標もしくは商標です。
                  <br />
                </p>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TopAbout;
