//react
import { Fragment } from "react";

//type
type PropsType = {
  answerList: Array<Array<string>>;
  answerMatchList: Array<Array<string>>;
};
type BindType = {
  letter: string;
  match: string;
};
const WordleDeeAnswer = (props: PropsType) => {
  const bindList = props.answerList.map((answer, listIndex) => {
    const answerMatch = props.answerMatchList[listIndex];
    return answer.map((value, index) => {
      return {
        letter: value,
        match: answerMatch[index],
      };
    });
  });

  const renderCol = (value: Array<BindType>) => {
    return value.map((value, index) => {
      return (
        <div
          key={index}
          className={
            "d-wordle-answerbox" +
            (value.match === "match-perfect"
              ? " d-wordle-match-perfect"
              : value.match === "match-letter"
              ? " d-wordle-match-letter"
              : value.match === "close-perfect"
              ? " d-wordle-close-perfect"
              : value.match === "not-match"
              ? " d-wordle-not-match"
              : value.match === "empty"
              ? " d-wordle-empty"
              : "")
          }
        >
          {value.letter}
        </div>
      );
    });
  };
  const renderRow1 = (bindList: Array<Array<BindType>>) => {
    return bindList.map((value, index) => {
      if (index >= 0 && index < 5) {
        return (
          <div className="d-wordle-answer" key={index}>
            {renderCol(value)}
          </div>
        );
      }
      return <Fragment key={index}></Fragment>;
    });
  };
  const renderRow2 = (bindList: Array<Array<BindType>>) => {
    return bindList.map((value, index) => {
      if (index >= 5 && index < 10) {
        return (
          <div className="d-wordle-answer" key={index}>
            {renderCol(value)}
          </div>
        );
      }
      return <Fragment key={index}></Fragment>;
    });
  };
  return (
    <>
      <div className="d-wordle-answerzone">
        <div className="d-wordle-answersubzone">{renderRow1(bindList)}</div>
        <div className="d-wordle-answersubzone">{renderRow2(bindList)}</div>
      </div>
    </>
  );
};

export default WordleDeeAnswer;
