//react
import React from "react";
import { Link } from "react-router-dom";
import ScrollTileText from "../common/ScrollTileText";

const TopCopy = () => {
  const textList = [
    {
      text: "星のカービィ",
    },
    {
      text: "夢の泉の物語",
    },
    {
      text: "カービィのピンボール",
    },
    {
      text: "カービィボール",
    },
    {
      text: "星のカービィ２",
    },
    {
      text: "カービィのブロックボール",
    },
    {
      text: "スーパーデラックス",
    },
  ];
  return (
    <>
      <h2>
        <i className="fas fa-gamepad p-acd-icon-star" />
        <Link to="/gametitle">ゲームタイトル</Link>
      </h2>
      <ScrollTileText textList={textList} />
    </>
  );
};

export default TopCopy;
