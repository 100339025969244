//react
import React from "react";
import { Link } from "react-router-dom";
import AccordionMenu from "../../parts/common/AccordionMenu";

const TopRequest = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="ぼしゅうイラスト"
            id="request"
            url=""
            contents={
              <>
                <div>
                  下記のイラストを募集中です！
                  <a
                    href="https://www.pupupuorder.com/app/potiboard/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    スケッチルーム
                  </a>
                  からお絵かき投稿or画像ファイル投稿が可能です。
                </div>
                <div>
                  縦横比が合っていれば解像度はどのくらいでも大丈夫です。
                  既に投稿されているイラストを投稿してもＯＫです。
                </div>
                <div>【募集イラスト】</div>
                <ul>
                  <li key="top-image">ＴＯＰ絵(目安400x400) </li>
                  <li key="copy-image">
                    コピー能力イラスト(目安100x100) 募集対象は
                    <Link to="/copy">こちら</Link>
                  </li>
                  <li key="enemy-image">
                    敵キャライラスト(目安100x100) 募集対象は
                    <Link to="/enemy">こちら</Link>
                  </li>
                  <li key="friend-image">
                    仲間キャラ/連携コピー能力イラスト(目安100x100) 募集対象は
                    <Link to="/friend">こちら</Link>
                  </li>
                </ul>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TopRequest;
