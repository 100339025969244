//react
import React, { useContext } from "react";

import { renderImageContainer } from "../utility/CommonFunc";
import { GlobalValueContext } from "../App";

const Enemy = () => {
  const imageList = useContext(GlobalValueContext)?.enemyList || [];

  const renderNormal = () => {
    return (
      <>
        <h1>てきキャラ</h1>
        {renderImageContainer("enemy", "boss", <h2>ボスキャラ</h2>, imageList)}
        {renderImageContainer(
          "enemy",
          "midboss",
          <h2>ちゅうボスキャラ</h2>,
          imageList
        )}
        {renderImageContainer("enemy", "zako", <h2>ザコキャラ</h2>, imageList)}
      </>
    );
  };
  return <>{renderNormal()}</>;
};

export default Enemy;
