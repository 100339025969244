//react
import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
//context
import { GlobalValueContext } from "../App";
//function
import { renderImageContainer } from "../utility/CommonFunc";
//type
type ImageDataType = {
  name: string;
  img_path: string;
  painter: string;
  type: string;
};

Chart.register(...registerables);

const Profile = () => {
  //searchParams
  const [searchParams] = useSearchParams();
  const account = searchParams.get("account") ?? "";
  //context
  const imageDataList = useContext(GlobalValueContext)?.imageDataList || [];
  //const
  const userImageList = imageDataList.filter((value) => {
    return value.painter === account;
  });
  const userCopyImageList = userImageList
    .filter((value) => {
      return value.type === "copy";
    })
    .map((value) => {
      return {
        name: value.name,
        img_path: value.img_path,
        painter: value.painter,
        type: value.subtype,
      };
    });
  const copyImageDataList = userCopyImageList.filter((value) => {
    return value.type === "copy";
  });
  const mixImageDataList = userCopyImageList.filter((value) => {
    return value.type === "mix";
  });
  const specialImageDataList = userCopyImageList.filter((value) => {
    return value.type === "special";
  });
  const metamorImageDataList = userCopyImageList.filter((value) => {
    return value.type === "metamor";
  });
  const superImageDataList = userCopyImageList.filter((value) => {
    return value.type === "super";
  });
  const campusImageDataList = userCopyImageList.filter((value) => {
    return value.type === "campus";
  });
  const jobImageDataList = userCopyImageList.filter((value) => {
    return value.type === "job";
  });
  const roboImageDataList = userCopyImageList.filter((value) => {
    return value.type === "robo";
  });
  const friendsImageDataList = userCopyImageList.filter((value) => {
    return value.type === "friends";
  });
  const saihoImageDataList = userCopyImageList.filter((value) => {
    return value.type === "saiho";
  });
  const shinkaImageDataList = userCopyImageList.filter((value) => {
    return value.type === "shinka";
  });
  const hobariImageDataList = userCopyImageList.filter((value) => {
    return value.type === "hobari";
  });
  const foodImageDataList = userCopyImageList.filter((value) => {
    return value.type === "food";
  });
  const userEnemyImageList = userImageList
    .filter((value) => {
      return value.type === "enemy";
    })
    .map((value) => {
      return {
        name: value.name,
        img_path: value.img_path,
        painter: value.painter,
        type: value.subtype,
      };
    });
  const bossImageDataList = userEnemyImageList.filter((value) => {
    return value.type === "boss";
  });
  const midbossImageDataList = userEnemyImageList.filter((value) => {
    return value.type === "midboss";
  });
  const zakoImageDataList = userEnemyImageList.filter((value) => {
    return value.type === "zako";
  });
  const userFriendImageList = userImageList
    .filter((value) => {
      return value.type === "friend";
    })
    .map((value) => {
      return {
        name: value.name,
        img_path: value.img_path,
        painter: value.painter,
        type: value.subtype,
      };
    });
  const kirbyImageDataList = userFriendImageList.filter((value) => {
    return value.type === "kirby";
  });
  const friendImageDataList = userFriendImageList.filter((value) => {
    return value.type === "friend";
  });
  const friendCopyImageDataList = userFriendImageList.filter((value) => {
    return value.type === "friend_copy";
  });
  const machineImageDataList = userFriendImageList.filter((value) => {
    return value.type === "airride_machine";
  });
  const ballImageDataList = userFriendImageList.filter((value) => {
    return value.type === "ball";
  });
  const vehicleImageDataList = userFriendImageList.filter((value) => {
    return value.type === "vehicle";
  });
  const otherImageDataList = userFriendImageList.filter((value) => {
    return value.type === "other";
  });
  const chartLabels = [
    "コピー能力",
    "ミックスコピー",
    "特殊能力",
    "メタモル能力",
    "スーパー能力",
    "キャンパス変身",
    "ジョブ",
    "ロボ",
    "フレンズ能力",
    "さいほう能力",
    "コピー能力進化",
    "ほおばりヘンケイ",
    "コピーフード",
    "ボスキャラ",
    "中ボスキャラ",
    "ザコキャラ",
    "なかまキャラ",
    "なかまコピー能力",
    "エアライドマシン",
    "ボール",
    "のりもの",
    "そのたキャラ",
  ].map((v) => v.replaceAll("ー", "丨").split(""));
  const chartValues: Array<number> = [
    copyImageDataList.length,
    mixImageDataList.length,
    specialImageDataList.length,
    metamorImageDataList.length,
    superImageDataList.length,
    campusImageDataList.length,
    jobImageDataList.length,
    roboImageDataList.length,
    friendsImageDataList.length,
    saihoImageDataList.length,
    shinkaImageDataList.length,
    hobariImageDataList.length,
    foodImageDataList.length,
    bossImageDataList.length,
    midbossImageDataList.length,
    zakoImageDataList.length,
    friendImageDataList.length,
    friendCopyImageDataList.length,
    machineImageDataList.length,
    ballImageDataList.length,
    vehicleImageDataList.length,
    otherImageDataList.length,
  ];

  const graphData = {
    labels: chartLabels,
    datasets: [
      {
        label: "投稿数",
        backgroundColor: [
          "rgba(255,127,127,0.5)",
          "rgba(255,127,191,0.5)",
          "rgba(255,127,255,0.5)",
          "rgba(191,127,255,0.5)",
          "rgba(127,127,255,0.5)",
          "rgba(127,191,255,0.5)",
          "rgba(127,255,255,0.5)",
          "rgba(127,255,191,0.5)",
          "rgba(127,255,127,0.5)",
          "rgba(191,255,127,0.5)",
          "rgba(255,255,127,0.5)",
          "rgba(255,191,127,0.5)",
        ],
        borderColor: "rgb(255, 255, 255)",
        data: chartValues,
      },
    ],
  };

  const options: {} = {
    color: "rgb(255, 255, 255)", //タイトルテキスト
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: { color: "rgb(255, 255, 255)", min: 0, stepSize: 1 }, //Y軸のラベル
      },
      x: {
        ticks: {
          color: "rgb(255, 255, 255)",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        }, //X軸のラベル
      },
    },
  };

  const renderIllustGraph = () => {
    return (
      <>
        <div className="p-container-chart">
          <div>
            <Bar data={graphData} options={options} height={300} />
          </div>
        </div>
      </>
    );
  };

  const renderIllust = (
    imageDataList: Array<ImageDataType>,
    type: string,
    subType: string,
    title: string
  ) => {
    return (
      <>
        {imageDataList.length !== 0 ? (
          renderImageContainer(type, subType, <h2>{title}</h2>, imageDataList)
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderIllustList = () => {
    return (
      <>
        {renderIllust(copyImageDataList, "copy", "copy", "コピーのうりょく")}
        {renderIllust(mixImageDataList, "copy", "mix", "ミックスコピー")}
        {renderIllust(
          specialImageDataList,
          "copy",
          "special",
          "とくしゅのうりょく"
        )}
        {renderIllust(
          metamorImageDataList,
          "copy",
          "metamor",
          "メタモルのうりょく"
        )}
        {renderIllust(
          superImageDataList,
          "copy",
          "super",
          "スーパーのうりょく"
        )}
        {renderIllust(
          campusImageDataList,
          "copy",
          "campus",
          "キャンパスへんしん"
        )}
        {renderIllust(jobImageDataList, "copy", "job", "ジョブ")}
        {renderIllust(roboImageDataList, "copy", "robo", "ロボ")}
        {renderIllust(
          friendsImageDataList,
          "copy",
          "friends",
          "フレンズのうりょく"
        )}
        {renderIllust(
          saihoImageDataList,
          "copy",
          "saiho",
          "さいほうのうりょく"
        )}
        {renderIllust(
          shinkaImageDataList,
          "copy",
          "shinka",
          "コピーのうりょくしんか"
        )}
        {renderIllust(
          hobariImageDataList,
          "copy",
          "hobari",
          "ほおばりヘンケイ"
        )}
        {renderIllust(foodImageDataList, "copy", "food", "コピーフード")}
        {renderIllust(bossImageDataList, "enemy", "boss", "ボスキャラ")}
        {renderIllust(midbossImageDataList, "enemy", "midboss", "ちゅうボス")}
        {renderIllust(zakoImageDataList, "enemy", "zako", "ザコキャラ")}
        {renderIllust(kirbyImageDataList, "friend", "kirby", "カービィ")}
        {renderIllust(friendImageDataList, "friend", "friend", "なかまキャラ")}
        {renderIllust(
          friendCopyImageDataList,
          "friend",
          "friend_copy",
          "なかまコピーのうりょく"
        )}
        {renderIllust(
          machineImageDataList,
          "friend",
          "airride_machine",
          "エアライドマシン"
        )}
        {renderIllust(ballImageDataList, "friend", "ball", "ボール")}
        {renderIllust(vehicleImageDataList, "friend", "vehicle", "のりもの")}
        {renderIllust(otherImageDataList, "friend", "other", "そのたキャラ")}
      </>
    );
  };

  const renderMessage = () => {
    return (
      <div className="p-container p-body-block">
        <div className="p-item-1">
          <div>
            <h2>メッセージ</h2>
          </div>
        </div>
      </div>
    );
  };

  const renderNormal = () => {
    return (
      <>
        <h1>
          <span className="p-h1-kanji">{account}さん</span>
        </h1>
        <div className="p-container p-body-block">
          <div className="p-item-1">
            <div>
              <h2>とうこうイラストすう</h2>
            </div>
            {renderIllustGraph()}
          </div>
        </div>
        {renderIllustList()}
        {renderMessage()}
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <div className="p-container p-body-block">
          <div className="p-item-1">ユーザ「{account}」は存在しません</div>
        </div>
      </>
    );
  };

  return (
    <>
      {userCopyImageList.length +
        userEnemyImageList.length +
        userFriendImageList.length !==
      0
        ? renderNormal()
        : renderError()}
    </>
  );
};

export default Profile;
