//react
import React, {
  Fragment,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
//component
import { GlobalValueContext } from "../App";
import AccordionMenu from "../parts/common/AccordionMenu";
import WordleDeeMain from "../parts/wordledee/WordleDeeMain";
import { deepCopy } from "../utility/Utility";
import { renderRecord } from "../parts/wordledee/WordleDeeFunc";
//type
type RankedHiscoreType = {
  create_date: string;
  rank?: number;
  account: string;
  game: string;
  score: number;
  remarks: string;
};

const WordleDee = () => {
  //context
  const hiscoreList = useContext(GlobalValueContext)?.hiscoreList || [];

  //ref
  const divElement = useRef<HTMLDivElement>(null);

  //state
  const [divWidth, setDivWidth] = useState(
    divElement.current?.getBoundingClientRect().width
  );

  //effect
  //リサイズ時に幅を取得する関数をセット
  useEffect(() => {
    setDivWidth(divElement.current?.getBoundingClientRect().width);
    const onResize = () => {
      setDivWidth(divElement.current?.getBoundingClientRect().width);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const
  //総合ハイスコア
  const wordleDeeHiscoreList = hiscoreList.filter((value) => {
    return value.game === "wordledee";
  });
  const sortedWordleDeeHiscoreList = [...wordleDeeHiscoreList].sort((a, b) => {
    return b.score - a.score;
  });
  let currentRank = 1;
  let currentScore = sortedWordleDeeHiscoreList[0]?.score || 0;
  let rankedWordleDeeHiscoreList: Array<RankedHiscoreType> = [];
  sortedWordleDeeHiscoreList.forEach((value) => {
    const tmpValue: RankedHiscoreType = deepCopy(value);
    if (value.score < currentScore) {
      currentRank++;
      currentScore = value.score;
    }
    tmpValue.rank = currentRank;
    rankedWordleDeeHiscoreList.push(tmpValue);
  });

  //月間ハイスコア
  const sortedWordleDeeMonthlyHiscoreList = sortedWordleDeeHiscoreList.filter(
    (value) => {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const targetDate = new Date(value.create_date);
      const targetYear = targetDate.getFullYear();
      const targetMonth = targetDate.getMonth() + 1;

      return currentYear === targetYear && currentMonth === targetMonth;
    }
  );
  let currentMonthlyRank = 1;
  let currentMonthlyScore = sortedWordleDeeMonthlyHiscoreList[0]?.score || 0;
  let rankedWordleDeeMonthlyHiscoreList: Array<RankedHiscoreType> = [];
  sortedWordleDeeMonthlyHiscoreList.forEach((value) => {
    const tmpValue: RankedHiscoreType = deepCopy(value);
    if (value.score < currentMonthlyScore) {
      currentMonthlyRank++;
      currentMonthlyScore = value.score;
    }
    tmpValue.rank = currentMonthlyRank;
    rankedWordleDeeMonthlyHiscoreList.push(tmpValue);
  });

  //日間ハイスコア
  const sortedWordleDeeDailyHiscoreList = sortedWordleDeeHiscoreList.filter(
    (value) => {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();
      const targetDate = new Date(value.create_date);
      const targetYear = targetDate.getFullYear();
      const targetMonth = targetDate.getMonth() + 1;
      const targetDay = targetDate.getDate();

      return (
        currentYear === targetYear &&
        currentMonth === targetMonth &&
        currentDay === targetDay
      );
    }
  );
  let currentDailyRank = 1;
  let currentDailyScore = sortedWordleDeeDailyHiscoreList[0]?.score || 0;
  let rankedWordleDeeDailyHiscoreList: Array<RankedHiscoreType> = [];
  sortedWordleDeeDailyHiscoreList.forEach((value) => {
    const tmpValue: RankedHiscoreType = deepCopy(value);
    if (value.score < currentDailyScore) {
      currentDailyRank++;
      currentDailyScore = value.score;
    }
    tmpValue.rank = currentDailyRank;
    rankedWordleDeeDailyHiscoreList.push(tmpValue);
  });

  const renderRecordImage = (remarks: string) => {
    let recordList: Array<Array<string>> = [];
    try {
      recordList = JSON.parse(remarks);
    } catch (error) {
      return <></>;
    }
    return renderRecord(recordList);
  };

  const renderHiscoreRow = (list: Array<RankedHiscoreType>) => {
    return list.map((value, index) => {
      if ((value?.rank || 10) >= 10) {
        return <Fragment key={index}></Fragment>;
      }
      return (
        <Fragment key={index}>
          <tr key={value.create_date + index}>
            <td className="p-text-center">
              {value.rank === 1 ? (
                <i className="fas fa-crown p-gold" />
              ) : value.rank === 2 ? (
                <i className="fas fa-crown p-silver" />
              ) : value.rank === 3 ? (
                <i className="fas fa-crown p-bronze" />
              ) : (
                <></>
              )}
            </td>
            <td className="p-text-center">{value.account}</td>
            <td className="p-text-center">{value.score}</td>
            <td className="p-text-center">{value.create_date}</td>
            <td className="p-text-center">
              <div className="p-container-center">
                {renderRecordImage(value.remarks)}
              </div>
            </td>
          </tr>
        </Fragment>
      );
    });
  };

  const renderHiscore = (list: Array<RankedHiscoreType>) => {
    return (
      <>
        <table className="p-table">
          <thead>
            <tr>
              <th className="p-text-center"></th>
              <th className="p-text-center">なまえ</th>
              <th className="p-text-center">スコア</th>
              <th className="p-text-center">日付</th>
              <th className="p-text-center">記録</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 ? (
              <tr>
                <td colSpan={5}>まだスコアがありません</td>
              </tr>
            ) : (
              renderHiscoreRow(list)
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <h1>Wordle-D</h1>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="ルール"
            id="rule"
            url=""
            contents={
              <ul>
                <li>Wordle-Dはワードあてゲームです</li>
                <li>５文字のカービィの敵キャラクターのワードが正解です</li>
                <li>
                  １０回まで回答できるので、選ばれたワードをあててください
                </li>
                <li>
                  正しい位置の文字は緑色🟩に、使われている文字は黄色🟨になります
                </li>
                <li>
                  位置が正しいが濁音（゛）、半濁音（゜）が異なる場合は文字はオレンジ🟧に表示されます
                </li>
              </ul>
            }
          />
        </div>
      </div>
      <div className="p-container" ref={divElement}>
        <div
          className={
            (divWidth || 700) >= 700 ? "p-2item-over700" : "p-2item-less700"
          }
        >
          <div className="p-body-block p-max-height">
            <h2>Wordle-D</h2>
            <WordleDeeMain />
          </div>{" "}
        </div>
        <div
          className={
            (divWidth || 700) >= 700 ? "p-2item-over700" : "p-2item-less700"
          }
        >
          <div className="p-body-block p-max-height">
            <h2>きょうのハイスコア</h2>
            {renderHiscore(rankedWordleDeeDailyHiscoreList)}
            <h2>こんげつのハイスコア</h2>
            {renderHiscore(rankedWordleDeeMonthlyHiscoreList)}
          </div>
        </div>
      </div>
    </>
  );
};

export default WordleDee;
