//react
import React, { useContext } from "react";
import { Link } from "react-router-dom";
//context
import { GlobalValueContext } from "../App";
//type
import { MemberType } from "../App";

const GuestBook = () => {
  //context
  // const imageDataList = useContext(GlobalValueContext)?.imageDataList || [];
  // const copyPainterList = imageDataList
  //   .filter((value) => {
  //     return value.type === "copy";
  //   })
  //   .map((value) => {
  //     return {
  //       name: value.name,
  //       img_path: value.img_path,
  //       painter: value.painter,
  //       type: value.subtype,
  //     };
  //   });
  //   const enemyPainterList = imageDataList
  //     .filter((value) => {
  //       return value.type === "enemy";
  //     })
  //     .map((value) => {
  //       return {
  //         name: value.name,
  //         img_path: value.img_path,
  //         painter: value.painter,
  //         type: value.subtype,
  //       };
  //     });
  //     const friendPainterList = imageDataList
  //       .filter((value) => {
  //         return value.type === "friend";
  //       })
  //       .map((value) => {
  //         return {
  //           name: value.name,
  //           img_path: value.img_path,
  //           painter: value.painter,
  //           type: value.subtype,
  //         };
  //       });

  const copyPainterList = useContext(GlobalValueContext)?.copyPainterList || [];
  const enemyPainterList =
    useContext(GlobalValueContext)?.enemyPainterList || [];
  const friendPainterList =
    useContext(GlobalValueContext)?.friendPainterList || [];

  const renderPainter = (list: Array<MemberType>) => {
    const renderRow = list.map((value, index) => {
      return (
        <tr key={value.painter + index}>
          <td>
            {value.rank === 1 ? (
              <i className="fas fa-crown p-gold" />
            ) : value.rank === 2 ? (
              <i className="fas fa-crown p-silver" />
            ) : value.rank === 3 ? (
              <i className="fas fa-crown p-bronze" />
            ) : (
              <></>
            )}
          </td>
          <td>
            <Link to={"/profile?account=" + value.painter}>
              {value.painter}
            </Link>
            様
          </td>
          <td className="p-text-center">{value.num}</td>
        </tr>
      );
    });
    return (
      <table className="p-table">
        <thead>
          <tr>
            <th className="p-text-center"></th>
            <th className="p-text-center">なまえ</th>
            <th className="p-text-center">投稿数</th>
          </tr>
        </thead>
        <tbody>{renderRow}</tbody>
      </table>
    );
  };

  return (
    <>
      <h1>
        ゲストブック<span className="p-h1-kanji"></span>
      </h1>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <div className="p-guestbook-container">
            <div className="p-guestbook-item">
              <h2 className="p-h2-kanji p-text-center">
                <div>コピー能力寄稿者</div>
              </h2>
              {renderPainter(copyPainterList)}
            </div>
            <div className="p-guestbook-item">
              <h2 className="p-h2-kanji p-text-center">
                <div>敵キャラ寄稿者</div>
              </h2>
              {renderPainter(enemyPainterList)}
            </div>
            <div className="p-guestbook-item">
              <h2 className="p-h2-kanji p-text-center">
                <div>仲間キャラ寄稿者</div>
              </h2>
              {renderPainter(friendPainterList)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestBook;
