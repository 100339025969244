//react
import React from "react";

type Image = {
  img_path: string;
  name: string;
  painter: string;
};

type Props = {
  imageList: Array<Image>;
};

const ScrollTileImage = (props: Props) => {
  const imageList: Array<Image> = props.imageList;
  const renderTile = imageList.map((value: Image) => {
    return (
      <li className="p-tile-item" key={value.img_path + value.name}>
        <div className="p-overlay-container">
          <img
            src={value.img_path}
            alt={value.name}
            title={value.name + "\n" + value.painter + " 様作"}
            className="p-overlay-image"
          />
          <p
            className={
              value.name.length > 11 ? "p-overlay-text2" : "p-overlay-text"
            }
          >
            {value.name.includes("募集") ? "" : value.name}
          </p>
        </div>
      </li>
    );
  });
  return (
    <div className="p-tile-wrap">
      <ul
        className={
          imageList.length > 20 ? "p-tile-list-fast" : "p-tile-list-slow"
        }
      >
        {renderTile}
      </ul>
      <ul
        className={
          imageList.length > 20 ? "p-tile-list-fast" : "p-tile-list-slow"
        }
      >
        {renderTile}
      </ul>
    </div>
  );
};

export default ScrollTileImage;
