//react
import React from "react";
import AccordionMenu from "../common/AccordionMenu";

const TopCommunication = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="こうりゅう"
            id="communication"
            url=""
            contents={
              <ul>
                <li key="pbbs" className="p-none-list">
                  <div className="p-container-left">
                    <div className="p-container-center p-align-center p-acd-icon-com">
                      <i className="fas fa-brush" />
                    </div>
                    <a href="https://www.pupupuorder.com/app/potiboard/index.html">
                      お絵描き掲示板
                    </a>
                  </div>
                </li>
                <li key="bbs" className="p-none-list">
                  <div className="p-container-left">
                    <div className="p-container-center p-align-center p-acd-icon-com">
                      <i className="fas fa-pen" />
                    </div>
                    <a
                      href="http://free2.nazca.co.jp/mk14/a55555/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      掲示板
                    </a>
                  </div>
                </li>
              </ul>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TopCommunication;
