//react
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  title: string | JSX.Element;
  id: string;
  url: string;
  contents: JSX.Element;
};

const AccordionMenu = (props: Props) => {
  const title: string | JSX.Element = props.title;
  const id: string = props.id;
  const url: string = props.url;
  const contents: JSX.Element = props.contents;

  return (
    <>
      <input type="checkbox" id={id} className="p-acd-check" />
      <label className="p-acd-label p-pointer" htmlFor={id}>
        <h2>
          <i className="fas fa-caret-right p-acd-icon-right" />
          <i className="fas fa-caret-down p-acd-icon-down" />
          {"" === url ? <span>{title}</span> : <Link to={url}>{title}</Link>}
        </h2>
      </label>
      <div className="p-acd-content">{contents}</div>
    </>
  );
};

export default AccordionMenu;
