//react
import React, { useState, useEffect, useRef } from "react";
import TopCopy from "../../parts/top/TopCopy";
import TopEnemy from "../../parts/top/TopEnemy";
import TopFriend from "../../parts/top/TopFriend";
import TopGameTitle from "../../parts/top/TopGameTitle";

const TopIllust = () => {
  //ref
  const divElement = useRef<HTMLDivElement>(null);

  //state
  const [divWidth, setDivWidth] = useState(
    divElement.current?.getBoundingClientRect().width
  );

  //effect
  //リサイズ時に幅を取得する関数をセット
  useEffect(() => {
    setDivWidth(divElement.current?.getBoundingClientRect().width);
    const onResize = () => {
      setDivWidth(divElement.current?.getBoundingClientRect().width);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="p-container">
        <div
          className={
            (divWidth || 0) >= 700
              ? "p-body-block p-2item-over700"
              : "p-body-block p-2item-less700"
          }
        >
          <TopCopy />
        </div>
        <div
          className={
            (divWidth || 0) >= 700
              ? "p-body-block p-2item-over700"
              : "p-body-block p-2item-less700"
          }
        >
          <TopEnemy />
        </div>
      </div>
      <div className="p-container" ref={divElement}>
        <div
          className={
            (divWidth || 0) >= 700
              ? "p-body-block p-2item-over700"
              : "p-body-block p-2item-less700"
          }
        >
          <TopFriend />
        </div>
        <div
          className={
            (divWidth || 0) >= 700
              ? "p-body-block p-2item-over700"
              : "p-body-block p-2item-less700"
          }
        >
          <TopGameTitle />
        </div>
      </div>
    </>
  );
};

export default TopIllust;
