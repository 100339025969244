//react
import React, { useContext, Fragment } from "react";
import { Link } from "react-router-dom";

import { GlobalValueContext } from "../../App";
import RandomTopImage from "../../parts/common/RandomTopImage";
import { maxLength } from "../../utility/Utility";

const TopTitle = () => {
  const historyList = useContext(GlobalValueContext)?.historyList || [];
  const footprintsList = useContext(GlobalValueContext)?.footprintsList || [];

  const backgroundImageList = [
    "/img/galaxy/galaxy01.jpg",
    "/img/galaxy/galaxy02.jpg",
    "/img/galaxy/galaxy03.jpg",
    "/img/galaxy/galaxy04.jpg",
    "/img/galaxy/galaxy05.jpg",
    "/img/galaxy/galaxy06.jpg",
    "/img/galaxy/galaxy07.jpg",
    "/img/galaxy/galaxy08.jpg",
    "/img/galaxy/galaxy09.jpg",
    "/img/galaxy/galaxy10.jpg",
    "/img/galaxy/galaxy11.jpg",
    "/img/galaxy/galaxy12.jpg",
    "/img/galaxy/galaxy13.jpg",
  ];
  const randomNum = Math.floor(Math.random() * backgroundImageList.length);
  const backgroundImage = backgroundImageList[randomNum];
  document.body.style.backgroundImage = `url("${backgroundImage}")`;

  const renderRecentHistory = () => {
    return historyList.map((value, index) => {
      if (index >= 3) {
        return <Fragment key={index}></Fragment>;
      }
      return (
        <div className="p-small-font" key={index}>
          {value.date} {maxLength(value.remarks, 26)}
        </div>
      );
    });
  };
  const renderRecentFootprints = () => {
    return footprintsList.map((value, index) => {
      if (index >= 3) {
        return <Fragment key={index}></Fragment>;
      }
      return (
        <div className="p-small-font" key={index}>
          {value.datetime} {value.account}さんが
          <Link to={value.path}>{value.service}</Link>を訪れました
        </div>
      );
    });
  };
  return (
    <div className="p-body-block p-container">
      <div className="p-3item">
        <h1 className="p-container-center">
          プププ<span className="p-h1-kanji">騎士団</span>
        </h1>
        <h2 className="p-container-center">
          <div className="p-text-center">カビメタファンサイト</div>
        </h2>
        {/* <div>過去訪問者数</div> */}
        {/* <div>現在閲覧者数</div> */}
        <div>
          <div className="rainbow p-text-center">
            <div>Wiiデラックスが発売されました！</div>
          </div>
          <div className="p-text-center">(2023/2/28)</div>
        </div>
      </div>
      <div className="p-3item p-text-center">
        <RandomTopImage />
      </div>
      <div className="p-3item">
        {navigator.userAgent.match(/iPhone|Android.+Mobile/) ? (
          <></>
        ) : (
          <div className="p-text-center">
            <div>
              <i className="fas fa-chevron-down p-acd-icon" />
              スマホ
            </div>
            <img src="img/p0089.png" className="p-qr-image" alt="QRコード" />
          </div>
        )}
        <div>
          <Link to="/history">更新履歴</Link>
        </div>
        <div>{renderRecentHistory()}</div>
        <div>あしあと</div>
        <div>{renderRecentFootprints()}</div>
      </div>
    </div>
  );
};

export default TopTitle;
