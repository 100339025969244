//react
import React from "react";
//component
import TopTitle from "../parts/top/TopTitle";
import TopAbout from "../parts/top/TopAbout";
import TopIllust from "../parts/top/TopIllust";
import TopGuestBook from "../parts/top/TopGuestBook";
import TopRequest from "../parts/top/TopRequest";
import TopMiniGame from "../parts/top/TopMiniGame";
import TopLink from "../parts/top/TopLink";
import TopCommunication from "../parts/top/TopCommunication";

const Index = () => {
  const renderNormal = () => {
    return (
      <>
        <TopTitle />
        <TopAbout />
        <TopIllust />
        <TopCommunication />
        <TopRequest />
        <TopMiniGame />
        <TopGuestBook />
        <TopLink />
      </>
    );
  };
  return <>{renderNormal()}</>;
};

export default Index;
