import axios from "axios";
import { Link } from "react-router-dom";

import { maxLength } from "./Utility";
import { ImageType } from "../App";

interface SetFuncType {
  (input: any): void;
}
type DataSetType = {
  dataName: string;
  setFunc: SetFuncType;
};

type ApiSetType = {
  apiPath: string;
  dataSet: Array<DataSetType>;
};

//APIからのデータロード
export const loadData = (apiSet: Array<ApiSetType>) => {
  apiSet.forEach((apiValue) => {
    axios
      .post("/app/api/" + apiValue.apiPath, {
        data: window.location.hostname,
      })
      .then((response) => {
        apiValue.dataSet.forEach((dataValue) => {
          dataValue.setFunc(response.data[dataValue.dataName]);
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  });
};
export const getData = (apiSet: Array<ApiSetType>) => {
  apiSet.forEach((apiValue) => {
    axios
      .get("/app/api/" + apiValue.apiPath, {
        params: {
          host: window.location.hostname,
        },
      })
      .then((response) => {
        apiValue.dataSet.forEach((dataValue) => {
          dataValue.setFunc(response.data[dataValue.dataName]);
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  });
};

//画像一枚分の描画
const renderImages = (imageList: Array<ImageType>, type: string) => {
  return imageList.map((value: ImageType) => {
    return (
      <div className="p-tile-item" key={value.name}>
        {value.img_path !== "" ? (
          <div className="p-overlay-container">
            <img
              src={value.img_path}
              alt={value.name}
              title={value.name + "\n" + value.painter + " 様作"}
              className="p-overlay-image"
            />
            <p
              className={
                value.name.length > 11 ? "p-overlay-text2" : "p-overlay-text"
              }
            >
              <Link to={"/detail?type=" + type + "&name=" + value.name}>
                {value.name}
              </Link>
            </p>
          </div>
        ) : (
          maxLength(value.name, 14) + "募集中!"
        )}
      </div>
    );
  });
};

//画像の一覧を表示
export const renderImageContainer = (
  type: string,
  subtype: string,
  typeName: JSX.Element,
  imageList: Array<ImageType>
) => {
  const images = imageList.filter((value) => {
    return value.type === subtype;
  });
  return (
    <div className="p-container p-body-block">
      <div className="p-item-1 p-text-left">
        {typeName}
        <div className="p-image-container">{renderImages(images, type)}</div>
      </div>
    </div>
  );
};
