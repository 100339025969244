//react
import React, { useRef } from "react";
const RandomTopImage = () => {
  // const imageList = [
  //   "img/p0001.jpg",
  //   "img/p0091.png",
  //   "img/p0092.png",
  //   "img/p0093.png",
  //   "img/p0094.png",
  //   "img/p0095.png",
  //   "img/p0096.png",
  //   "img/p0097.png",
  //   "img/p0279.jpg",
  // ];
  const imageList = [
    "img/p0095.png",
    "img/p0096.png",
    "img/p0097.png",
    "img/p0279.jpg",
    "img/p0336.jpg",
  ];

  const randomNum = useRef(Math.floor(Math.random() * imageList.length));

  return (
    <img
      src={imageList[randomNum.current]}
      alt="トップ絵"
      className="p-top-image"
    />
  );
};

export default RandomTopImage;
