//react
import React from "react";

const AppFooter = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const renderNormal = () => {
    return (
      <>
        <div className="p-pointer p-half-background" onClick={handleClick}>
          <i className="fas fa-chevron-up p-acd-icon" />
        </div>
      </>
    );
  };
  return <>{renderNormal()}</>;
};

export default AppFooter;
