//JSXを含まない汎用関数

//最長文字数へ丸め
export const maxLength = (text: string, num: number) => {
  let returnText = text.substring(0, num);
  if (text.length > num) {
    returnText += "...";
  }
  return returnText;
};

//配列のシャッフル
export const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

//乱数の取得(0~maxNum)
export const makeRandomNum = (maxNum: number) => {
  return Math.floor(Math.random() * (maxNum + 1));
};

//クッキーの保存
export const setCookie = (cookieKey: string, cookieValue: string) => {
  const cookieDomain = "www.pupupuorder.com";
  const maxAgeSeconds = 60 * 60 * 24 * 30; //30日
  document.cookie =
    "" +
    cookieKey +
    "=" +
    encodeURIComponent(cookieValue) +
    "; " +
    "Max-Age=" +
    String(maxAgeSeconds) +
    "; " +
    "Domain=" +
    cookieDomain +
    "; " +
    "SameSite=strict; " +
    +"Secure;";
};

//クッキーの取得
export const getCookie = (cookieKey: string) => {
  const originCookie = document.cookie;
  const arrayValue = originCookie.split(";");

  for (let value of arrayValue) {
    const cookie = value.split("=");
    if (cookie[0] === cookieKey) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return "";
};

//オブジェクトのディープコピー
export const deepCopy = (inputValue: any) => {
  return JSON.parse(JSON.stringify(inputValue));
};
