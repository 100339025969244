//react
import React, { useContext } from "react";
import { useSearchParams, Link } from "react-router-dom";
//context
import { GlobalValueContext } from "../App";
//function
import { typeMap, subTypeMap } from "../utility/ConstName";
import { renderImageContainer } from "../utility/CommonFunc";
//type

const Detail = () => {
  //searchParams
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") ?? "";
  const name = searchParams.get("name") ?? "";
  //context
  const copyList = useContext(GlobalValueContext)?.copyList || [];
  const enemyList = useContext(GlobalValueContext)?.enemyList || [];
  const friendList = useContext(GlobalValueContext)?.friendList || [];
  const imageDataList = useContext(GlobalValueContext)?.imageDataList || [];
  const gameTitleList = useContext(GlobalValueContext)?.gameTitleList || [];

  //const
  const dataList =
    type === "copy"
      ? copyList
      : type === "enemy"
      ? enemyList
      : type === "friend"
      ? friendList
      : [];
  const targetList = dataList.filter((value) => {
    return value.name === name;
  });
  const targetData = targetList?.[0] ?? null;
  const subType = targetData?.type ?? "";

  const typeName = typeMap[type] ?? "不明";
  const subTypeName = subTypeMap[subType] ?? "不明";

  const filteredGameTitleList = gameTitleList.filter((value) => {
    return value.game_title !== "星のカービィ 20周年スペシャルコレクション";
  });
  const filteredImageDataList = imageDataList.filter((value) => {
    return value.name === name && value.type === type;
  });

  const renderBasicData = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>なまえ：</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>しゅるい：</td>
            <td>{typeName}</td>
          </tr>
          <tr>
            <td>タイプ：</td>
            <td>{subTypeName}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderGameTitle = () => {
    const renderTile = filteredGameTitleList.map((value) => {
      const gameTitleName =
        value.game_title
          .replaceAll("星のカービィ ", "")
          .replaceAll("カービィの", "") ?? "";
      const targetNameList: Array<string> =
        type === "copy"
          ? JSON.parse(value.copy_list)
          : type === "enemy"
          ? JSON.parse(value.enemy_list)
          : type === "friend"
          ? JSON.parse(value.friend_list)
          : [];
      return (
        <div className="p-tile-item-margin" key={value.game_title ?? ""}>
          <div
            className={
              targetNameList.includes(name)
                ? "p-tile-item-text-active"
                : "p-tile-item-text-tile"
            }
          >
            {targetNameList.includes(name) ? (
              <Link
                to={
                  "/gametitle?gametitle=" + encodeURIComponent(value.game_title)
                }
              >
                {gameTitleName}
              </Link>
            ) : (
              <p>{gameTitleName}</p>
            )}
          </div>
        </div>
      );
    });
    return <div className="p-image-container">{renderTile}</div>;
  };

  const renderNormal = () => {
    return (
      <>
        <h1>{name}</h1>
        <div className="p-container p-body-block">
          <div className="p-item-1">
            <div>
              <h2>きほんじょうほう</h2>
            </div>
            {renderBasicData()}
          </div>
        </div>
        {renderImageContainer(
          type,
          type,
          <h2>とうこうイラスト</h2>,
          filteredImageDataList
        )}
        <div className="p-container p-body-block">
          <div className="p-item-1">
            <div>
              <h2>とうじょうさくひん</h2>
            </div>
            {renderGameTitle()}
          </div>
        </div>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <div className="p-container p-body-block">
          <div className="p-item-1">「{name}」のデータが存在しません</div>
        </div>
      </>
    );
  };

  return <>{targetList.length === 1 ? renderNormal() : renderError()}</>;
};

export default Detail;
