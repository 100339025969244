//react
import React from "react";

type Text = {
  text: string;
};

type Props = {
  textList: Array<Text>;
};

const ScrollTile = (props: Props) => {
  const imageList: Array<Text> = props.textList;
  const renderTile = imageList.map((value: Text) => {
    return (
      <li className="p-tile-item" key={value["text"]}>
        <div className="p-tile-item-text">{value["text"]}</div>
      </li>
    );
  });
  return (
    <div className="p-tile-wrap">
      <ul className="p-tile-list-slow">{renderTile}</ul>
      <ul className="p-tile-list-slow">{renderTile}</ul>
    </div>
  );
};

export default ScrollTile;
