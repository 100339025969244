//react
import React, { useContext, useEffect, Fragment } from "react";
import { useSearchParams, Link } from "react-router-dom";

import { maxLength } from "../utility/Utility";
import { GlobalValueContext } from "../App";

type ImageType = {
  name: string;
  img_path: string;
  painter: string;
  type: string;
};

const GameTitle = () => {
  //searchParams
  const [searchParams] = useSearchParams();
  const gametitle = searchParams.get("gametitle") ?? "";
  //context
  const gameTitleList = useContext(GlobalValueContext)?.gameTitleList || [];
  const friendList = useContext(GlobalValueContext)?.friendList || [];
  const copyList = useContext(GlobalValueContext)?.copyList || [];
  const enemyList = useContext(GlobalValueContext)?.enemyList || [];

  useEffect(() => {
    if (gametitle !== "") {
      const targetElement = document.getElementById(gametitle);
      targetElement?.scrollIntoView(true);
    }
  }, [gametitle]);

  const getImagePath = (imageName: string, imageList: Array<ImageType>) => {
    const result = imageList.find((value) => {
      return value.name === imageName;
    });
    if (result === undefined) {
      return "not_found_in_list";
    }
    return result.img_path;
  };

  const renderImage = (
    gameList: Array<string>,
    targetList: Array<ImageType>,
    type: string
  ) => {
    return gameList.map((imageName) => {
      const imagePath = getImagePath(imageName, targetList);
      return (
        <div className="" key={imageName}>
          {imagePath ===
          "not_found_in_list" /* 個別情報がない場合は*を出力 */ ? (
            <div className="p-small-tile">{maxLength(imageName, 15)}*</div>
          ) : imagePath !== undefined && imagePath !== "" ? (
            <div className="p-overlay-container">
              <img
                src={imagePath}
                alt={imageName}
                title={imageName}
                className="p-small-image"
              />
              <p className="p-overlay-text3">
                <Link to={"/detail?type=" + type + "&name=" + imageName}>
                  {imageName}
                </Link>
              </p>
            </div>
          ) : (
            <div className="p-small-tile">{maxLength(imageName, 15)}</div>
          )}
        </div>
      );
    });
  };

  const renderGameTitle = gameTitleList.map((value) => {
    return (
      <Fragment key={value.game_title}>
        <tr>
          <td colSpan={3} className="p-text-center" id={value.game_title}>
            <div>　</div>
            <div>{value.game_title}</div>
            <div>({value.date_of_issue.replaceAll("-", "/")}発売)</div>
            <div>　</div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="p-image-container">
              {renderImage(JSON.parse(value.friend_list), friendList, "friend")}
            </div>
          </td>
          <td>
            <div className="p-image-container">
              {renderImage(JSON.parse(value.copy_list), copyList, "copy")}
            </div>
          </td>
          <td>
            <div className="p-image-container">
              {renderImage(JSON.parse(value.enemy_list), enemyList, "enemy")}
            </div>
          </td>
        </tr>
      </Fragment>
    );
  });

  const renderNormal = () => {
    return (
      <>
        <h1>ゲームタイトル</h1>
        <div className="p-container p-body-block">
          <div className="p-item-1 p-text-left">
            <table>
              <thead>
                <tr>
                  <th>仲間キャラ</th>
                  <th>コピー能力</th>
                  <th>敵キャラ</th>
                </tr>
              </thead>
              <tbody>{renderGameTitle}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  };
  return <>{renderNormal()}</>;
};

export default GameTitle;
