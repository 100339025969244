//react
import React from "react";
import { Link } from "react-router-dom";

const TopLink = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <h2>
            <i className="fas fa-share-square p-acd-icon-star" />
            <Link to="/link">リンク</Link>
          </h2>
        </div>
      </div>
    </>
  );
};

export default TopLink;
