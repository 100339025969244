//react
import React, {
  Fragment,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
//component
import { GlobalValueContext } from "../App";
import AccordionMenu from "../parts/common/AccordionMenu";
import WordChainMain from "../parts/wordchain/WordChainMain";
import { deepCopy } from "../utility/Utility";
//type
import { ImageType } from "../App";
type RankedHiscoreType = {
  create_date: string;
  rank?: number;
  account: string;
  game: string;
  score: number;
  remarks: string;
};

const getPath = (list: Array<ImageType>, imageName: string) => {
  const targetImage = list.find((value) => {
    return value.name === imageName;
  });
  return targetImage?.img_path || "";
};

const WordChain = () => {
  //context
  const hiscoreList = useContext(GlobalValueContext)?.hiscoreList || [];
  const enemyList = useContext(GlobalValueContext)?.enemyList || [];
  const friendList = useContext(GlobalValueContext)?.friendList || [];
  const copyList = useContext(GlobalValueContext)?.copyList || [];
  const targetList = enemyList.concat(friendList).concat(copyList);

  //ref
  const divElement = useRef<HTMLDivElement>(null);

  //state
  const [divWidth, setDivWidth] = useState(
    divElement.current?.getBoundingClientRect().width
  );

  //effect
  //リサイズ時に幅を取得する関数をセット
  useEffect(() => {
    setDivWidth(divElement.current?.getBoundingClientRect().width);
    const onResize = () => {
      setDivWidth(divElement.current?.getBoundingClientRect().width);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const
  //総合ハイスコア
  const wordChainHiscoreList = hiscoreList.filter((value) => {
    return value.game === "wordchain";
  });
  const sortedWordChainHiscoreList = [...wordChainHiscoreList].sort((a, b) => {
    return Number(b.score) - Number(a.score);
  });
  let currentRank = 1;
  let currentScore = sortedWordChainHiscoreList[0]?.score || 0;
  let rankedWordChainHiscoreList: Array<RankedHiscoreType> = [];
  sortedWordChainHiscoreList.forEach((value) => {
    const tmpValue: RankedHiscoreType = deepCopy(value);
    if (Number(value.score) < Number(currentScore)) {
      currentRank++;
      currentScore = value.score;
    }
    tmpValue.rank = currentRank;
    rankedWordChainHiscoreList.push(tmpValue);
  });

  //月間ハイスコア
  const sortedWordChainMonthlyHiscoreList = sortedWordChainHiscoreList.filter(
    (value) => {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const targetDate = new Date(value.create_date);
      const targetYear = targetDate.getFullYear();
      const targetMonth = targetDate.getMonth() + 1;

      return currentYear === targetYear && currentMonth === targetMonth;
    }
  );
  let currentMonthlyRank = 1;
  let currentMonthlyScore = sortedWordChainMonthlyHiscoreList[0]?.score || 0;
  let rankedWordChainMonthlyHiscoreList: Array<RankedHiscoreType> = [];
  sortedWordChainMonthlyHiscoreList.forEach((value) => {
    const tmpValue: RankedHiscoreType = deepCopy(value);
    if (Number(value.score) < Number(currentMonthlyScore)) {
      currentMonthlyRank++;
      currentMonthlyScore = value.score;
    }
    tmpValue.rank = currentMonthlyRank;
    rankedWordChainMonthlyHiscoreList.push(tmpValue);
  });

  const renderRecordImage = (remarks: string) => {
    let recordList: Array<string> = [];
    try {
      recordList = JSON.parse(remarks);
    } catch (error) {
      return <></>;
    }
    return recordList.map((value, index) => {
      if (value === "") {
        return <Fragment key={index}></Fragment>;
      }
      return (
        <Fragment key={index}>
          {index !== 0 ? (
            <i className="fas fa-caret-right p-margin-next" />
          ) : (
            <></>
          )}
          <img
            src={getPath(targetList, value)}
            alt={value}
            title={value}
            className="p-tile-item-small"
          />
        </Fragment>
      );
    });
  };

  const renderHiscoreRow = (list: Array<RankedHiscoreType>) => {
    return list.map((value, index) => {
      if ((value?.rank || 6) >= 6) {
        return <Fragment key={index}></Fragment>;
      }
      return (
        <tr
          key={
            value.account + value.create_date + JSON.stringify(value.remarks)
          }
        >
          <td className="p-text-center">
            {value.rank === 1 ? (
              <i className="fas fa-crown p-gold" />
            ) : value.rank === 2 ? (
              <i className="fas fa-crown p-silver" />
            ) : value.rank === 3 ? (
              <i className="fas fa-crown p-bronze" />
            ) : (
              <></>
            )}
          </td>
          <td className="p-text-center">{value.account}</td>
          <td className="p-text-center">{value.score}</td>
          <td className="p-text-center">{value.create_date}</td>
          <td>
            <div className="p-inline-container">
              {renderRecordImage(value.remarks)}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderHiscore = (list: Array<RankedHiscoreType>) => {
    return (
      <>
        <table className="p-table">
          <thead>
            <tr>
              <th className="p-text-center"></th>
              <th className="p-text-center">なまえ</th>
              <th className="p-text-center">スコア</th>
              <th className="p-text-center">日付</th>
              <th className="p-text-center">記録</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 ? (
              <tr>
                <td colSpan={5}>まだスコアがありません</td>
              </tr>
            ) : (
              renderHiscoreRow(list)
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <h1>イラストしりとり</h1>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="ルール"
            id="rule"
            url=""
            contents={
              <ul>
                <li>
                  カービィのコピー能力/敵キャラ/味方キャラでしりとりをします。ハイスコアを目指しましょう！
                </li>
                <li>イラストのあるキャラクターが候補として表示されます</li>
                <li>
                  候補のイラストの中からキャラクターを選び名前を入力して回答してください
                </li>
                <li>
                  「リセット」ボタンを押すと最初からやり直せます。何度リセットしてもOK！
                </li>
                <li>
                  イラストのないキャラクターは
                  <a
                    href="https://www.pupupuorder.com/app/potiboard/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    スケッチルーム
                  </a>
                  に寄稿すると選択可能になります
                </li>
                <li>
                  末尾について、長音（ー）は無視します。濁音（゛）、半濁音（゜）は外しても良いです。拗音（ゃゅょ等）は清音に戻します
                </li>
              </ul>
            }
          />
        </div>
      </div>
      <div className="p-container" ref={divElement}>
        <div
          className={
            (divWidth || 700) >= 700 ? "p-2item-over700" : "p-2item-less700"
          }
        >
          <div className="p-body-block p-max-height">
            <h2>イラストしりとり</h2>
            <WordChainMain />
          </div>{" "}
        </div>
        <div
          className={
            (divWidth || 700) >= 700 ? "p-2item-over700" : "p-2item-less700"
          }
        >
          <div className="p-body-block p-max-height">
            <h2>こんげつのハイスコア</h2>
            {renderHiscore(rankedWordChainMonthlyHiscoreList)}
            <h2>そうごうハイスコア</h2>
            {renderHiscore(rankedWordChainHiscoreList)}
          </div>
        </div>
      </div>
    </>
  );
};

export default WordChain;
