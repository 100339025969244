//react
import React, { useContext } from "react";

import { renderImageContainer } from "../utility/CommonFunc";
import { GlobalValueContext } from "../App";

const Friend = () => {
  const imageList = useContext(GlobalValueContext)?.friendList || [];

  const renderNormal = () => {
    return (
      <>
        <h1>なかまキャラ</h1>
        {renderImageContainer(
          "friend",
          "friend",
          <h2>なかまキャラ</h2>,
          imageList
        )}
        {renderImageContainer(
          "friend",
          "friend_copy",
          <h2>なかまコピーのうりょく</h2>,
          imageList
        )}
        {renderImageContainer(
          "friend",
          "airride_machine",
          <h2>エアライドマシン</h2>,
          imageList
        )}
        {renderImageContainer("friend", "ball", <h2>ボール</h2>, imageList)}
        {renderImageContainer(
          "friend",
          "vehicle",
          <h2>のりもの</h2>,
          imageList
        )}
        {renderImageContainer(
          "friend",
          "other",
          <h2>そのたキャラ</h2>,
          imageList
        )}
      </>
    );
  };
  return <>{renderNormal()}</>;
};

export default Friend;
