//react
import { Fragment, useState, useContext } from "react";
import axios from "axios";

//context
import { GlobalValueContext } from "../../App";
import { GlobalSetValueContext } from "../../App";
//function
import {
  setCookie,
  getCookie,
  makeRandomNum,
  deepCopy,
} from "../../utility/Utility";
import { loadData } from "../../utility/CommonFunc";
//type
import { ImageType } from "../../App";

const conversionTable = [
  ["ガ", "カ"],
  ["ギ", "キ"],
  ["グ", "ク"],
  ["ゲ", "ケ"],
  ["ゴ", "コ"],
  ["ザ", "サ"],
  ["ジ", "シ"],
  ["ズ", "ス"],
  ["ゼ", "セ"],
  ["ゾ", "ソ"],
  ["ダ", "タ"],
  ["ヂ", "チ"],
  ["ヅ", "ツ"],
  ["デ", "テ"],
  ["ド", "ト"],
  ["バ", "ハ"],
  ["ビ", "ヒ"],
  ["ブ", "フ"],
  ["ベ", "へ"],
  ["ボ", "ホ"],
  ["パ", "ハ"],
  ["ピ", "ヒ"],
  ["プ", "フ"],
  ["ペ", "ヘ"],
  ["ポ", "ホ"],
  ["ァ", "ア"],
  ["ィ", "イ"],
  ["ゥ", "ウ"],
  ["ェ", "エ"],
  ["ォ", "オ"],
  ["ッ", "ツ"],
  ["ャ", "ヤ"],
  ["ュ", "ユ"],
  ["ョ", "ヨ"],
];

const conversionKana = (inputValue: string) => {
  if (inputValue.length !== 1) {
    return "";
  }
  let returnValue = inputValue;
  conversionTable.forEach((tuple) => {
    if (tuple[0] === inputValue) {
      returnValue = tuple[1];
    }
  });
  return returnValue;
};

const getPath = (list: Array<ImageType>, imageName: string) => {
  const targetImage = list.find((value) => {
    return value.name === imageName;
  });
  return targetImage?.img_path || "";
};

const getLastLetter = (list: Array<string>) => {
  const lastWord = list[list.length - 1];
  const lastLetter = lastWord[lastWord.length - 1];
  let fixedLastLetter = lastLetter;
  if (lastLetter === "ー") {
    fixedLastLetter = lastWord[lastWord.length - 2];
  }
  return conversionKana(fixedLastLetter);
};

const getCandidateList = (
  currentList: Array<string>,
  targetList: Array<ImageType>
) => {
  const candidateList = targetList.filter((value) => {
    return value.name[0] === getLastLetter(currentList) && value.painter !== "";
  });
  const distinctCandidateList = candidateList.filter((value) => {
    return !currentList.includes(value.name);
  });
  return distinctCandidateList;
};

const getPreCandidateList = (
  currentList: Array<string>,
  targetList: Array<ImageType>
) => {
  const candidateList = targetList.filter((value) => {
    return value.name[0] === getLastLetter(currentList) && value.painter === "";
  });
  const distinctCandidateList = candidateList.filter((value) => {
    return !currentList.includes(value.name);
  });
  return distinctCandidateList;
};

const WordChainMain = () => {
  //context
  const enemyList = useContext(GlobalValueContext)?.enemyList || [];
  const friendList = useContext(GlobalValueContext)?.friendList || [];
  const copyList = useContext(GlobalValueContext)?.copyList || [];
  const targetList = enemyList.concat(friendList).concat(copyList);
  const setHiscoreList = useContext(GlobalSetValueContext)?.setHiscoreList;
  const setFootprintsList = useContext(
    GlobalSetValueContext
  )?.setFootprintsList;
  const filteredTargetList = targetList.filter((value) => {
    return value.painter !== "" && value.painter !== null;
  });
  //state
  const [gameScene, setGameScene] = useState("title");
  const [account, setAccount] = useState(getCookie("account"));
  const [currentChain, setCurrentChain] = useState<Array<string>>([]);
  const [answer, setAnswer] = useState("");

  const handleStart = () => {
    const continuableTargetList = filteredTargetList.filter((value) => {
      return getCandidateList([value.name], targetList).length > 0;
    });
    const startWord =
      continuableTargetList[makeRandomNum(continuableTargetList.length - 1)]
        ?.name;

    setCurrentChain([startWord]);
    setAnswer("");
    setCookie("account", account);
    setGameScene("ingame");
  };

  const renderTitle = () => {
    return (
      <div>
        <div>ハンドルネーム</div>
        <div>
          <input
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
            }}
            placeholder="ハンドルネーム"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleStart();
              }
            }}
          />
        </div>
        <div>
          <button onClick={handleStart} disabled={account === ""}>
            はじめる
          </button>
        </div>
      </div>
    );
  };

  const handleAnswer = () => {
    if (getCandidateList(currentChain, targetList).length === 0) {
      handleRegist();
    }
    if (
      getCandidateList(currentChain, targetList)
        .map((value) => value.name)
        .includes(answer)
    ) {
      setCurrentChain((oldList) => {
        const newList = deepCopy(oldList);
        newList.push(answer);
        setAnswer("");
        return newList;
      });
    }
  };

  const handleRegist = () => {
    axios
      .post("/app/api/score", {
        data: window.location.hostname,
        account: account,
        game: "wordchain",
        score: currentChain.length,
        remarks: JSON.stringify(currentChain),
      })
      .then((response) => {
        if (setHiscoreList !== undefined) {
          loadData([
            {
              apiPath: "hiscore",
              dataSet: [{ dataName: "result", setFunc: setHiscoreList }],
            },
          ]);
        }
        if (setFootprintsList !== undefined) {
          loadData([
            {
              apiPath: "footprints",
              dataSet: [{ dataName: "result", setFunc: setFootprintsList }],
            },
          ]);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    setGameScene("result");
  };

  const handleReset = () => {
    handleStart();
  };

  const renderCurrentChain = () => {
    return currentChain.map((value, index) => {
      return (
        <Fragment key={index}>
          <img
            src={getPath(targetList, value)}
            alt={value}
            title={value}
            className="p-tile-item-midium"
          />
          <i className="fas fa-caret-right p-margin-next" />
        </Fragment>
      );
    });
  };

  const renderCandidate = () => {
    const candidateList = getCandidateList(currentChain, targetList);
    if (candidateList.length === 0) {
      return (
        <>
          <div>つなげられるイラストがありません</div>
          <div>「スコア登録」か「リセット」してください</div>
        </>
      );
    }
    return candidateList.map((value, index) => {
      return (
        <Fragment key={index}>
          <img
            src={value.img_path}
            className="p-tile-item-midium"
            alt="candidate"
          />
        </Fragment>
      );
    });
  };

  const renderHint = () => {
    const candidateList = getCandidateList(currentChain, targetList);
    const preCandidateList = getPreCandidateList(currentChain, targetList);
    return (
      <>
        <input type="checkbox" id="hint" className="p-acd-check" />
        <label className="p-acd-label p-pointer" htmlFor="hint">
          <h3>
            <i className="fas fa-caret-right p-acd-icon-right" />
            <i className="fas fa-caret-down p-acd-icon-down" />
            ヒント
          </h3>
        </label>
        <div className="p-acd-content">
          <h4>「{getLastLetter(currentChain)}～」のこうほイラストのよみ</h4>
          <ul>
            {candidateList.length === 0 ? "こうほがありません" : ""}
            {candidateList.map((value) => {
              return <li key={value.name}>{value.name}</li>;
            })}
          </ul>
          <h4>イラストのない「{getLastLetter(currentChain)}～」のこうほ</h4>
          <div>
            <a
              href="https://www.pupupuorder.com/app/potiboard/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              スケッチルーム
            </a>
            に下記を投稿するとこうほに追加されます
          </div>
          <ul>
            {preCandidateList.length === 0 ? "こうほがありません" : ""}
            {preCandidateList.map((value, index) => {
              return <li key={value.name + index}>{value.name}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  const renderIngame = () => {
    const candidateList = getCandidateList(currentChain, targetList);
    const preCandidateList = getPreCandidateList(currentChain, targetList);
    const preCandidateNameList = preCandidateList.map((value) => {
      return value.name;
    });
    return (
      <div>
        <div>
          <h3>
            れんさじょうきょう(
            <span className="rainbow">{currentChain.length}れんさ</span>)
          </h3>
          <div>{renderCurrentChain()}</div>
        </div>
        <div>
          <h3>
            つぎは
            {currentChain[currentChain.length - 1]}の「
            {getLastLetter(currentChain)}」
          </h3>
          <div>
            <input
              value={answer}
              placeholder={"" + getLastLetter(currentChain)}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAnswer();
                }
              }}
              autoFocus
            />
            <button
              onClick={handleAnswer}
              disabled={
                answer === "" ||
                candidateList.length === 0 ||
                !candidateList.map((value) => value.name).includes(answer)
              }
            >
              こたえる
            </button>
          </div>
          {preCandidateNameList.includes(answer) ? (
            <>「{answer}」はイラスト投稿されると回答できるようになります</>
          ) : (
            <></>
          )}
          <div>
            <button
              onClick={handleRegist}
              disabled={candidateList.length !== 0}
              className={candidateList.length === 0 ? "p-blink-border" : ""}
            >
              スコア登録
            </button>
            <button onClick={handleReset}>リセット</button>
          </div>
        </div>
        <div>
          <h3>「{getLastLetter(currentChain)}～」のこうほイラスト</h3>
          <div>{renderCandidate()}</div>
        </div>
        <div>
          <div>{renderHint()}</div>
        </div>
      </div>
    );
  };

  const renderResult = () => {
    return (
      <div>
        <div>
          <h3>けっかはっぴょう</h3>
          <div>
            スコアは<span className="rainbow">{currentChain.length}れんさ</span>
            でした！
          </div>
          <div>{renderCurrentChain()}</div>
        </div>
        <div>
          <button onClick={handleReset}>さいちょうせん</button>
        </div>
      </div>
    );
  };

  return (
    <>
      {gameScene === "ingame"
        ? renderIngame()
        : gameScene === "result"
        ? renderResult()
        : renderTitle()}
    </>
  );
};

export default WordChainMain;
