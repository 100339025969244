//react
import React, { useState, useContext, Fragment } from "react";
import Form from "react-bootstrap/Form";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
//component
import { GlobalValueContext } from "../App";

type HistoryType = {
  date: string;
  remarks: string;
};

Chart.register(...registerables);

const History = () => {
  const today = new Date();
  const oldYear = 2004;
  const currrentYear = today.getFullYear();

  const [selectYear, setSelectYear] = useState<number>(currrentYear);
  const historyList = useContext(GlobalValueContext)?.historyList || [];

  const renderRemarks = (remarks: string) => {
    const remarksArray = remarks.split("\n");
    return remarksArray.map((value, index) => {
      return (
        <Fragment key={index}>
          {value}
          {remarksArray.length - 1 > index ? <br /> : <></>}
        </Fragment>
      );
    });
  };

  const renderHistory = (list: HistoryType[]) => {
    return list.map((value, index) => {
      return (
        <tr key={"" + index + value.date}>
          <td>{value.date}</td>
          <td>{renderRemarks(value.remarks)}</td>
        </tr>
      );
    });
  };

  const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectYear(Number(e.target.value));
  };

  const filteredHistoryList = historyList.filter((value) => {
    const date = new Date(value.date);
    const valueYear = date.getFullYear();
    return valueYear === selectYear;
  });

  const chartLabels: Array<string> = [];
  const chartValues: Array<number> = [];
  for (let cnt = oldYear; cnt <= currrentYear; cnt++) {
    const year = ("0000" + String(cnt)).slice(-4);
    chartLabels.push(year);
    const yearHistoryList = historyList.filter((value) => {
      const date = new Date(value.date);
      const valueYear = date.getFullYear();
      return valueYear === Number(year);
    });
    const count = yearHistoryList.length;
    chartValues.push(count);
  }

  const graphData = {
    labels: chartLabels,
    datasets: [
      {
        label: "更新回数",
        data: chartValues,
        borderColor: "rgb(255, 255, 255)",
      },
    ],
  };

  const options: {} = {
    color: "rgb(255, 255, 255)",
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: { color: "rgb(255, 255, 255)" },
      },
      x: {
        ticks: { color: "rgb(255, 255, 255)" },
      },
    },
  };

  return (
    <>
      <h1>
        <span className="p-h1-kanji">更新履歴</span>
      </h1>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <div className="p-container-history">
            <Line data={graphData} options={options} />
          </div>
          <div className="p-container-between">
            <div>{oldYear}年</div>
            <div>{currrentYear}年</div>
          </div>
          <Form.Range
            id="year"
            onChange={handleChangeYear}
            value={selectYear}
            min={oldYear}
            max={currrentYear}
          />
          <h2>
            {selectYear}
            <span className="p-h1-kanji">年</span>のできごと
          </h2>
          <table className="p-table">
            <thead>
              <tr>
                <th className="p-table-min-width-s">年月日</th>
                <th>できごと</th>
              </tr>
            </thead>
            <tbody>{renderHistory(filteredHistoryList)}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default History;
