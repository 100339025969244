//react
import React from "react";
import { Link } from "react-router-dom";
//component
import AccordionMenu from "../common/AccordionMenu";

const TopMiniGame = () => {
  return (
    <>
      <div className="p-container p-body-block">
        <div className="p-item-1 p-text-left">
          <AccordionMenu
            title="ミニゲーム"
            id="minigame"
            url=""
            contents={
              <>
                <ul>
                  <li key="wordchain">
                    <Link to="/wordchain">イラストしりとり</Link>
                  </li>
                  <li key="copy-image">
                    <Link to="/wordledee">Wordle-D</Link>
                  </li>
                </ul>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TopMiniGame;
