//react
import { useState } from "react";

//type
interface FuncType {
  (): void;
}
interface InputFuncType {
  (input: string): void;
}
type PropsType = {
  enterFunc: FuncType;
  deleteFunc: FuncType;
  inputFunc: InputFuncType;
  matchPerfectList: Array<string>;
  matchLetterList: Array<string>;
  closePerfectList: Array<string>;
  notMatchList: Array<string>;
  enterDisabled: boolean;
  deleteDisabled: boolean;
};

const seionMap = [
  ["ア", "カ", "サ", "タ", "ナ", "ハ", "マ", "ヤ", "ラ", "ワ"],
  ["イ", "キ", "シ", "チ", "ニ", "ヒ", "ミ", "　", "リ", "　"],
  ["ウ", "ク", "ス", "ツ", "ヌ", "フ", "ム", "ユ", "ル", "ン"],
  ["エ", "ケ", "セ", "テ", "ネ", "ヘ", "メ", "　", "レ", "　"],
  ["オ", "コ", "ソ", "ト", "ノ", "ホ", "モ", "ヨ", "ロ", "ー"],
];

const dakuonMap = [
  ["ァ", "ガ", "ザ", "ダ", "　", "バ", "パ", "ャ", "　", "　"],
  ["ィ", "ギ", "ジ", "ヂ", "　", "ビ", "ピ", "　", "　", "　"],
  ["ゥ", "グ", "ズ", "ヅ", "ッ", "ブ", "プ", "ュ", "　", "　"],
  ["ェ", "ゲ", "ゼ", "デ", "　", "ベ", "ペ", "　", "　", "　"],
  ["ォ", "ゴ", "ゾ", "ド", "　", "ボ", "ポ", "ョ", "　", "　"],
];

const WordleDeeInput = (props: PropsType) => {
  //state
  const [displayType, setDisplayType] = useState("seion");

  //const
  const handleShift = () => {
    if (displayType === "seion") {
      setDisplayType("dakuon");
    } else {
      setDisplayType("seion");
    }
  };

  const renderButton = (letter: string) => {
    return (
      <button
        className={
          "d-wordle-inputzone-button" +
          (props.matchPerfectList.includes(letter)
            ? " d-wordle-match-perfect"
            : props.matchLetterList.includes(letter)
            ? " d-wordle-match-letter"
            : props.notMatchList.includes(letter)
            ? " d-wordle-not-match"
            : " d-wordle-empty")
        }
        onClick={() => {
          setDisplayType("seion");
          props.inputFunc(letter);
        }}
      >
        {letter}
      </button>
    );
  };

  const renderCol = (colList: Array<string>) => {
    return colList.map((value, index) => {
      return (
        <div className="d-wordle-inputzone-box" key={index}>
          {value !== "　" ? renderButton(value) : <></>}
        </div>
      );
    });
  };

  const renderRow = (seionMap: Array<Array<string>>) => {
    return seionMap.map((colList, index) => {
      return (
        <div className="d-wordle-inputzone-row" key={index}>
          {renderCol(colList)}
        </div>
      );
    });
  };

  const renderSeion = () => {
    return <div className="d-wordle-inputzone-key">{renderRow(seionMap)}</div>;
  };
  const renderDakuon = () => {
    return <div className="d-wordle-inputzone-key">{renderRow(dakuonMap)}</div>;
  };
  return (
    <>
      <div className="d-wordle-inputzone">
        <div className="d-wordle-inputzone-function">
          <div>
            <button onClick={handleShift}>゛゜</button>
          </div>
          <div>
            <button onClick={props.enterFunc} disabled={props.enterDisabled}>
              Enter
            </button>
          </div>
          <div>
            <button onClick={props.deleteFunc} disabled={props.deleteDisabled}>
              ←
            </button>
          </div>
        </div>
        {displayType === "seion" ? renderSeion() : <></>}
        {displayType === "dakuon" ? renderDakuon() : <></>}
      </div>
    </>
  );
};

export default WordleDeeInput;
