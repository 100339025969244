//react
import React from "react";
import { Link } from "react-router-dom";

const AppHeader = () => {
  const renderNormal = () => {
    return (
      <div className="p-header-menu-container">
        <div className="p-header-menu-inner">
          <Link to="/">
            <div className="p-margin-next-wide p-text-nowrap">ププ騎士</div>
          </Link>
          <Link className="p-header-menu-item p-text-center" to="/copy">
            <i className="fas fa-hat-wizard p-acd-icon" title="コピー能力" />
          </Link>
          <Link className="p-header-menu-item p-text-center" to="/enemy">
            <i className="fas fa-crosshairs p-acd-icon" title="敵キャラ" />
          </Link>
          <Link className="p-header-menu-item p-text-center" to="/friend">
            <i className="fas fa-heart p-acd-icon" title="仲間キャラ" />
          </Link>
          <Link className="p-header-menu-item p-text-center" to="/gametitle">
            <i className="fas fa-gamepad p-acd-icon" title="ゲームタイトル" />
          </Link>
          <a
            className="p-header-menu-item p-text-center"
            href="https://www.pupupuorder.com/app/potiboard/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-brush p-acd-icon" title="お絵描き掲示板" />
          </a>
          <a
            className="p-header-menu-item p-text-center"
            href="http://free2.nazca.co.jp/mk14/a55555/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-pen p-acd-icon" title="掲示板" />
          </a>
          <Link className="p-header-menu-item p-text-center" to="/guestbook">
            <i className="fas fa-book p-acd-icon" title="ゲストブック" />
          </Link>
          <Link className="p-header-menu-item p-text-center" to="/link">
            <i className="fas fa-share-square p-acd-icon" title="リンク" />
          </Link>
        </div>
      </div>
    );
  };
  return <>{renderNormal()}</>;
};

export default AppHeader;
