//react
import React, { useState, useRef, useEffect, createContext } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  // ScrollRestoration,
} from "react-router-dom";
//component
import AppHeader from "./component/AppHeader";
import AppFooter from "./component/AppFooter";
import Top from "./component/Top";
import Copy from "./component/Copy";
import Enemy from "./component/Enemy";
import Friend from "./component/Friend";
import GameTitle from "./component/GameTitle";
import GuestBook from "./component/GuestBook";
import Profile from "./component/Profile";
import Detail from "./component/Detail";
import Link from "./component/Link";
import History from "./component/History";
import WordChain from "./component/WordChain";
import WordleDee from "./component/WordleDee";
import ScrollToTop from "./parts/common/ScrollToTop";
import { loadData, getData } from "./utility/CommonFunc";
//css
import "./App.css";
import "./css/style.css";
import "./css/rainbow.css";

type HistoryType = {
  date: string;
  remarks: string;
};
export type ImageType = {
  name: string;
  img_path: string;
  painter: string;
  type: string;
};
export type GameTitleType = {
  game_title: string;
  date_of_issue: string;
  friend_list: string;
  copy_list: string;
  enemy_list: string;
};
export type MemberType = {
  rank: number;
  painter: string;
  num: number;
};
export type LinkType = {
  site_name: string;
  site_url: string;
  img_url: string;
  manager: string;
  active: boolean;
  link_date: string;
  unlink_date: string;
  establish_date: string;
  remarks: string;
};
export type HiscoreType = {
  create_date: string;
  account: string;
  game: string;
  score: number;
  remarks: string;
};
export type FootprintsType = {
  datetime: string;
  account: string;
  service: string;
  path: string;
};
export type ImageDataType = {
  img_path: string;
  name: string;
  type: string;
  subtype: string;
  painter: string;
  remarks: string;
  img_data: string;
  img_size: number;
  img_hash: string;
};
interface ValueContextDict {
  historyList: Array<HistoryType>;
  copyList: Array<ImageType>;
  enemyList: Array<ImageType>;
  friendList: Array<ImageType>;
  gameTitleList: Array<GameTitleType>;
  copyPainterList: Array<MemberType>;
  enemyPainterList: Array<MemberType>;
  friendPainterList: Array<MemberType>;
  linkList: Array<LinkType>;
  unlinkList: Array<LinkType>;
  hiscoreList: Array<HiscoreType>;
  footprintsList: Array<FootprintsType>;
  imageDataList: Array<ImageDataType>;
}
interface SetValueContextDict {
  setHistoryList: React.Dispatch<React.SetStateAction<Array<HistoryType>>>;
  setCopyList: React.Dispatch<React.SetStateAction<Array<ImageType>>>;
  setEnemyList: React.Dispatch<React.SetStateAction<Array<ImageType>>>;
  setFriendList: React.Dispatch<React.SetStateAction<Array<ImageType>>>;
  setGameTitleList: React.Dispatch<React.SetStateAction<Array<GameTitleType>>>;
  setCopyPainterList: React.Dispatch<React.SetStateAction<Array<MemberType>>>;
  setEnemyPainterList: React.Dispatch<React.SetStateAction<Array<MemberType>>>;
  setFriendPainterList: React.Dispatch<React.SetStateAction<Array<MemberType>>>;
  setLinkList: React.Dispatch<React.SetStateAction<Array<LinkType>>>;
  setUnlinkList: React.Dispatch<React.SetStateAction<Array<LinkType>>>;
  setHiscoreList: React.Dispatch<React.SetStateAction<Array<HiscoreType>>>;
  setFootprintsList: React.Dispatch<
    React.SetStateAction<Array<FootprintsType>>
  >;
  setImageDataList: React.Dispatch<React.SetStateAction<Array<ImageDataType>>>;
}
export const GlobalValueContext = createContext<ValueContextDict | null>(null);
export const GlobalSetValueContext = createContext<SetValueContextDict | null>(
  null
);

const App = () => {
  const [historyList, setHistoryList] = useState<Array<HistoryType>>([]);
  const [copyList, setCopyList] = useState<Array<ImageType>>([]);
  const [enemyList, setEnemyList] = useState<Array<ImageType>>([]);
  const [friendList, setFriendList] = useState<Array<ImageType>>([]);
  const [gameTitleList, setGameTitleList] = useState<Array<GameTitleType>>([]);
  const [copyPainterList, setCopyPainterList] = useState<Array<MemberType>>([]);
  const [enemyPainterList, setEnemyPainterList] = useState<Array<MemberType>>(
    []
  );
  const [friendPainterList, setFriendPainterList] = useState<Array<MemberType>>(
    []
  );
  const [linkList, setLinkList] = useState<Array<LinkType>>([]);
  const [unlinkList, setUnlinkList] = useState<Array<LinkType>>([]);
  const [hiscoreList, setHiscoreList] = useState<Array<HiscoreType>>([]);
  const [footprintsList, setFootprintsList] = useState<Array<FootprintsType>>(
    []
  );
  const [imageDataList, setImageDataList] = useState<Array<ImageDataType>>([]);

  const valueContext = {
    historyList: historyList,
    copyList: copyList,
    enemyList: enemyList,
    friendList: friendList,
    gameTitleList: gameTitleList,
    copyPainterList: copyPainterList,
    enemyPainterList: enemyPainterList,
    friendPainterList: friendPainterList,
    linkList: linkList,
    unlinkList: unlinkList,
    hiscoreList: hiscoreList,
    footprintsList: footprintsList,
    imageDataList: imageDataList,
  };

  const setValueContext = {
    setHistoryList: setHistoryList,
    setCopyList: setCopyList,
    setEnemyList: setEnemyList,
    setFriendList: setFriendList,
    setGameTitleList: setGameTitleList,
    setCopyPainterList: setCopyPainterList,
    setEnemyPainterList: setEnemyPainterList,
    setFriendPainterList: setFriendPainterList,
    setLinkList: setLinkList,
    setUnlinkList: setUnlinkList,
    setHiscoreList: setHiscoreList,
    setFootprintsList: setFootprintsList,
    setImageDataList: setImageDataList,
  };

  const loaded = useRef(false);
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      loadData([
        {
          apiPath: "history",
          dataSet: [{ dataName: "result", setFunc: setHistoryList }],
        },
      ]);
      loadData([
        {
          apiPath: "copy",
          dataSet: [{ dataName: "result", setFunc: setCopyList }],
        },
      ]);
      loadData([
        {
          apiPath: "enemy",
          dataSet: [{ dataName: "result", setFunc: setEnemyList }],
        },
      ]);
      loadData([
        {
          apiPath: "friend",
          dataSet: [{ dataName: "result", setFunc: setFriendList }],
        },
      ]);
      loadData([
        {
          apiPath: "gametitle",
          dataSet: [{ dataName: "result", setFunc: setGameTitleList }],
        },
      ]);
      loadData([
        {
          apiPath: "painter",
          dataSet: [
            { dataName: "copyResult", setFunc: setCopyPainterList },
            { dataName: "enemyResult", setFunc: setEnemyPainterList },
            { dataName: "friendResult", setFunc: setFriendPainterList },
          ],
        },
      ]);
      loadData([
        {
          apiPath: "link",
          dataSet: [
            { dataName: "linkResult", setFunc: setLinkList },
            { dataName: "unlinkResult", setFunc: setUnlinkList },
          ],
        },
      ]);
      loadData([
        {
          apiPath: "hiscore",
          dataSet: [{ dataName: "result", setFunc: setHiscoreList }],
        },
      ]);
      loadData([
        {
          apiPath: "footprints",
          dataSet: [{ dataName: "result", setFunc: setFootprintsList }],
        },
      ]);
      loadData([
        {
          apiPath: "footprints",
          dataSet: [{ dataName: "result", setFunc: setFootprintsList }],
        },
      ]);
      getData([
        {
          apiPath: "image",
          dataSet: [{ dataName: "result", setFunc: setImageDataList }],
        },
      ]);
    }
  }, []);
  return (
    <GlobalValueContext.Provider value={valueContext}>
      <GlobalSetValueContext.Provider value={setValueContext}>
        <div className="p-body">
          <Router>
            <ScrollToTop />
            {/* <ScrollRestoration /> */}
            <div className="p-app">
              <div className="p-app-header p-container-middle p-align-center">
                <AppHeader />
              </div>
              <div className="p-app-body">
                <Routes>
                  <Route path="/" element={<Top />} />
                  <Route path="/top" element={<Top />} />
                  <Route path="/copy" element={<Copy />} />
                  <Route path="/enemy" element={<Enemy />} />
                  <Route path="/friend" element={<Friend />} />
                  <Route path="/gametitle" element={<GameTitle />} />
                  <Route path="/guestbook" element={<GuestBook />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/detail" element={<Detail />} />
                  <Route path="/link" element={<Link />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/wordchain" element={<WordChain />} />
                  <Route path="/wordledee" element={<WordleDee />} />
                  <Route path="*" element={<Top />} />
                </Routes>
              </div>
              <div className="p-app-footer">
                <AppFooter />
              </div>
            </div>
          </Router>
        </div>
      </GlobalSetValueContext.Provider>
    </GlobalValueContext.Provider>
  );
};

export default App;
