import { Fragment } from "react";

const kanaList = [
  "ア",
  "カ",
  "サ",
  "タ",
  "ナ",
  "ハ",
  "マ",
  "ヤ",
  "ラ",
  "ワ",
  "イ",
  "キ",
  "シ",
  "チ",
  "ニ",
  "ヒ",
  "ミ",
  "リ",
  "ウ",
  "ク",
  "ス",
  "ツ",
  "ヌ",
  "フ",
  "ム",
  "ユ",
  "ル",
  "エ",
  "ケ",
  "セ",
  "テ",
  "ネ",
  "ヘ",
  "メ",
  "レ",
  "オ",
  "コ",
  "ソ",
  "ト",
  "ノ",
  "ホ",
  "モ",
  "ヨ",
  "ロ",
  "ー",
  "ァ",
  "ガ",
  "ザ",
  "ダ",
  "バ",
  "パ",
  "ャ",
  "ィ",
  "ギ",
  "ジ",
  "ヂ",
  "ビ",
  "ピ",
  "ゥ",
  "グ",
  "ズ",
  "ヅ",
  "ッ",
  "ブ",
  "プ",
  "ュ",
  "ェ",
  "ゲ",
  "ゼ",
  "デ",
  "ベ",
  "ペ",
  "ォ",
  "ゴ",
  "ゾ",
  "ド",
  "ボ",
  "ポ",
  "ョ",
  "ン",
];

const isKana = (letter: string) => {
  return kanaList.includes(letter);
};

export const hasOnlyKana = (input: string) => {
  if (input.length !== 5) {
    return false;
  }
  return (
    isKana(input.charAt(0)) &&
    isKana(input.charAt(1)) &&
    isKana(input.charAt(2)) &&
    isKana(input.charAt(3)) &&
    isKana(input.charAt(4))
  );
};

const conversionTable = [
  ["ガ", "カ"],
  ["ギ", "キ"],
  ["グ", "ク"],
  ["ゲ", "ケ"],
  ["ゴ", "コ"],
  ["ザ", "サ"],
  ["ジ", "シ"],
  ["ズ", "ス"],
  ["ゼ", "セ"],
  ["ゾ", "ソ"],
  ["ダ", "タ"],
  ["ヂ", "チ"],
  ["ヅ", "ツ"],
  ["デ", "テ"],
  ["ド", "ト"],
  ["バ", "ハ"],
  ["ビ", "ヒ"],
  ["ブ", "フ"],
  ["ベ", "へ"],
  ["ボ", "ホ"],
  ["パ", "ハ"],
  ["ピ", "ヒ"],
  ["プ", "フ"],
  ["ペ", "ヘ"],
  ["ポ", "ホ"],
  ["ァ", "ア"],
  ["ィ", "イ"],
  ["ゥ", "ウ"],
  ["ェ", "エ"],
  ["ォ", "オ"],
  ["ッ", "ツ"],
  ["ャ", "ヤ"],
  ["ュ", "ユ"],
  ["ョ", "ヨ"],
];

const conversionKana = (inputValue: string) => {
  if (inputValue.length !== 1) {
    return "";
  }
  const match = conversionTable.find((tuple) => {
    return tuple[0] === inputValue;
  });
  if (match !== undefined) {
    return match[1];
  }
  return inputValue;
};

export const isClose = (str1: string, str2: string) => {
  if (str1 === str2) {
    return false;
  }
  return conversionKana(str1) === conversionKana(str2);
};

const renderCol = (value: Array<string>) => {
  return value.map((value, index) => {
    return (
      <div key={index} className="d-wordle-answer-tile">
        {value === "match-perfect"
          ? "🟩"
          : value === "match-letter"
          ? "🟨"
          : value === "close-perfect"
          ? "🟧"
          : value === "not-match"
          ? "⬜"
          : value === "empty"
          ? "　"
          : ""}
      </div>
    );
  });
};

const renderRow1 = (matchList: Array<Array<string>>) => {
  return matchList.map((value, index) => {
    if (index >= 0 && index < 5) {
      return (
        <div className="d-wordle-answer" key={index}>
          {renderCol(value)}
        </div>
      );
    }
    return <Fragment key={index}></Fragment>;
  });
};
const renderRow2 = (matchList: Array<Array<string>>) => {
  return matchList.map((value, index) => {
    if (index >= 5 && index < 10) {
      return (
        <div className="d-wordle-answer" key={index}>
          {renderCol(value)}
        </div>
      );
    }
    return <Fragment key={index}></Fragment>;
  });
};

export const renderRecord = (answerMatchList: Array<Array<string>>) => {
  return (
    <>
      <div className="d-wordle-answerzone">
        <div className="d-wordle-answersubzone">
          {renderRow1(answerMatchList)}
        </div>
        <div className="d-wordle-answersubzone">
          {renderRow2(answerMatchList)}
        </div>
      </div>
    </>
  );
};
